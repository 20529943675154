import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Discover from "../components/Discover";
import Footer from "../components/Footer";
import Header from "../components/HeaderYoga";
import Nav from "../components/Nav";
import Yogaa from "../assests/yoga1.png";
import Yogaa2 from "../assests/yoga2.png";
import Sidebar from "../components/Sidebar";
import CardsComponent from "../components/CardsComponent";
import UploadImage from "../components/UploadImage";

const Yoga1 = () => {
    const routePath = useLocation();
    useEffect(()=>{
    window.scrollTo(0,0);
  },[routePath.pathname])
  return (
    <div>
      <Nav />
      <Sidebar />
      <Header
        image1={Yogaa}
        image2={Yogaa2}
        video="https://www.youtube.com/embed/jTX8MSw0Ufw"
      />
      <UploadImage/>
      <CardsComponent />
      <Discover />
      <Footer />
    </div>
  );
};

export default Yoga1;
