import React from "react";
import G from "../../assests/g.png";
import Card1 from "../../assests/card1.png";
import Card2 from "../../assests/card2.png";
import Card3 from "../../assests/card3.png";
import Cards7 from "../Cards7";

const Article = () => {
  const cardlist = [
    {
      title:
        "Lorem ipsum -  Dolor sit amet,  aiscin elit. Phellus , sit amet ruterat libero ",
      image: Card1,
      description:
        "Lorem ipsum -  Dolor sit amet,  aiscin elit. Phellus , sit amet ruterat libero vitae felis  Dolor j ddjorem ipsuet ruter atlibero vitae felis -  Dolor sit amet,  aiscin elit. Pharem ipsum lor sit",
      time:"10 minutes ago",
      read:"Read Full"
    },
    {
      title:
        "Lorem ipsum -  Dolor sit amet,  aiscin elit. Phellus , sit amet ruterat libero ",
      image: Card2,
      description:
        "Lorem ipsum -  Dolor sit amet,  aiscin elit. Phellus , sit amet ruterat libero vitae felis  Dolor j ddjorem ipsuet ruter atlibero vitae felis -  Dolor sit amet,  aiscin elit. Pharem ipsum lor sit",
        time:"10 minutes ago",
        read:"Read Full"
    },
    {
      title:
        "Lorem ipsum -  Dolor sit amet,  aiscin elit. Phellus , sit amet ruterat libero ",
      image: Card3,
      description:
        "Lorem ipsum -  Dolor sit amet,  aiscin elit. Phellus , sit amet ruterat libero vitae felis  Dolor j ddjorem ipsuet ruter atlibero vitae felis -  Dolor sit amet,  aiscin elit. Pharem ipsum lor sit ",
        time:"10 minutes ago",
        read:"Read Full"
    },
  ];
  return (
    <div className="bg-[#e7f1fe] py-20">
      <div className="md:mx-10 mx-3 lg:mx-20 xl:mx-20 box-content border-2 rounded-3xl  border-cyan-500 bg-slate-100 ">
        <img src={G} alt="g" className="xl:w-[1670px] xl:h-[570px] lg:w-[1570px] lg:h-[370px] rounded-3xl" />
        <div className="py-10 mx-10">
          <div className="text-[#023059] my-6 text-xl font-semibold flex justify-start">
            <p>
              Lorem ipsum - Dolor sit amet, aiscin elit. Phellus , sit amet
              ruterat libero{" "}
            </p>
          </div>
          <div className="text-[#7E7E7E] text-xs lg:text-sm space-y-5 font-medium">
            <p>
              {" "}
              Lorem ipsum - Dolor sit amet, aiscin elit. Phellus , sit amet
              ruterat libero vitae felis - Dolor j ddjorem ipsuet ruter atlibero
              vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum lor
              sitLorem ipsum - DoloLorem ipsum - Dolor sit amet, aiscin elit.
              Phellus , sit amet ruterat libero vitae felis - Dolor j ddjorem
              ipsuet ruter atlibero vitae felis - Dolor sit amet, aiscin elit.
              Pharem ipsum lor sitit amet, aiscin elit. Phellus , sit amet
              ruterat libero vitae felis - Dolor j ddjorem ipsuet ruter atlibero
              vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum lor
              sitLorem ipsum - DoloLorem ipsum - Dolor sit amet, aiscin elit.
              Phellus , sit amet ruterat libero vitae felis - Dolor j ddjorem
              ipsuet ruter atlibero vitae felis - Dolor sit amet, aiscin elit.
              Pharem ipsum lor sit St amet, aiscin elit. Phellus , sit amet
              ruterat libero vitae felis - Dolor j ddjorem ipsuet ruter atlibero
              vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum lor sitit
              amet, aiscin elit. Phellus , sit amet ruterat libero vitae felis -
              Dolor j ddjorem ipsuet ruter atlibero vitae felis - Dolor sit
              amet, aiscin elit.
            </p>
            <p>
              Pharem ipsum lor sitLorem ipsum - DoloLorem ipsum - Dolor sit
              amet, aiscin elit. Phellus , sit amet ruterat libero vitae felis -
              Dolor j ddjorem ipsuet ruter atlibero vitae felis - Dolor sit
              amet, aiscin elit. Pharem ipsum lor sit Lorem ipsum - Dolor sit
              amet, aiscin elit. Phellus , sit amet ruterat libero vitae felis -
              Dolor j ddjorem ipsuet ruter atlibero vitae felis - Dolor sit
              amet, aiscin elit. Pharem ipsum lor sitet, aiscin elit. Phellus ,
              sit amet ruterat libero vitae felis - Dolor j ddjorem ipsuet ruter
              atlibero vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum
              lor sitit amet, aiscin elit. Phellus , sit amet ruterat libero
              vitae felis - Dolor j ddjorem ipsuet ruter atlibero vitae felis -
              Dolor sit amet, aiscin elit.
            </p>
            <p>
              Lorem ipsum - Dolor sit amet, aiscin elit. Phellus , sit amet
              ruterat libero vitae felis - Dolor j ddjorem ipsuet ruter atlibero
              vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum lor
              sitLorem ipsum - DoloLorem ipsum - Dolor sit amet, aiscin elit.
              Phellus , sit amet ruterat libero vitae felis - Dolor j ddjorem
              ipsuet ruter atlibero vitae felis - Dolor sit amet, aiscin elit.
              Pharem ipsum lor sitit amet, aiscin elit. Phellus , sit amet
              ruterat libero vitae felis - Dolor j ddjorem ipsuet ruter atlibero
              vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum lor
              sitLorem ipsum - DoloLorem ipsum - Dolor sit amet, aiscin elit.
              Phellus , sit amet ruterat libero vitae felis - Dolor j ddjorem
              ipsuet ruter atlibero vitae felis - Dolor sit amet, aiscin elit.
              Pharem ipsum lor sit St amet, aiscin elit. Phellus , sit amet
              ruterat libero vitae felis - Dolor j ddjorem ipsuet ruter atlibero
              vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum lor sitit
              amet, aiscin elit. Phellus , sit amet ruterat libero vitae felis -
              Dolor j ddjorem ipsuet ruter atlibero vitae felis - Dolor sit
              amet, aiscin elit.
            </p>
          </div>
          <div className="text-[#7E7E7E] text-xs lg:text-sm space-y-5 mt-6 font-medium">
            <p>
              {" "}
              Lorem ipsum - Dolor sit amet, aiscin elit. Phellus , sit amet
              ruterat libero vitae felis - Dolor j ddjorem ipsuet ruter atlibero
              vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum lor
              sitLorem ipsum - DoloLorem ipsum - Dolor sit amet, aiscin elit.
              Phellus , sit amet ruterat libero vitae felis - Dolor j ddjorem
              ipsuet ruter atlibero vitae felis - Dolor sit amet, aiscin elit.
              Pharem ipsum lor sitit amet, aiscin elit. Phellus , sit amet
              ruterat libero vitae felis - Dolor j ddjorem ipsuet ruter atlibero
              vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum lor
              sitLorem ipsum - DoloLorem ipsum - Dolor sit amet, aiscin elit.
              Phellus , sit amet ruterat libero vitae felis - Dolor j ddjorem
              ipsuet ruter atlibero vitae felis - Dolor sit amet, aiscin elit.
              Pharem ipsum lor sit St amet, aiscin elit. Phellus , sit amet
              ruterat libero vitae felis - Dolor j ddjorem ipsuet ruter atlibero
              vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum lor sitit
              amet, aiscin elit. Phellus , sit amet ruterat libero vitae felis -
              Dolor j ddjorem ipsuet ruter atlibero vitae felis - Dolor sit
              amet, aiscin elit.
            </p>
            <p>
              Pharem ipsum lor sitLorem ipsum - DoloLorem ipsum - Dolor sit
              amet, aiscin elit. Phellus , sit amet ruterat libero vitae felis -
              Dolor j ddjorem ipsuet ruter atlibero vitae felis - Dolor sit
              amet, aiscin elit. Pharem ipsum lor sit Lorem ipsum - Dolor sit
              amet, aiscin elit. Phellus , sit amet ruterat libero vitae felis -
              Dolor j ddjorem ipsuet ruter atlibero vitae felis - Dolor sit
              amet, aiscin elit. Pharem ipsum lor sitet, aiscin elit. Phellus ,
              sit amet ruterat libero vitae felis - Dolor j ddjorem ipsuet ruter
              atlibero vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum
              lor sitit amet, aiscin elit. Phellus , sit amet ruterat libero
              vitae felis - Dolor j ddjorem ipsuet ruter atlibero vitae felis -
              Dolor sit amet, aiscin elit.
            </p>
            <p>
              Lorem ipsum - Dolor sit amet, aiscin elit. Phellus , sit amet
              ruterat libero vitae felis - Dolor j ddjorem ipsuet ruter atlibero
              vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum lor
              sitLorem ipsum - DoloLorem ipsum - Dolor sit amet, aiscin elit.
              Phellus , sit amet ruterat libero vitae felis - Dolor j ddjorem
              ipsuet ruter atlibero vitae felis - Dolor sit amet, aiscin elit.
              Pharem ipsum lor sitit amet, aiscin elit. Phellus , sit amet
              ruterat libero vitae felis - Dolor j ddjorem ipsuet ruter atlibero
              vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum lor
              sitLorem ipsum - DoloLorem ipsum - Dolor sit amet, aiscin elit.
              Phellus , sit amet ruterat libero vitae felis - Dolor j ddjorem
              ipsuet ruter atlibero vitae felis - Dolor sit amet, aiscin elit.
              Pharem ipsum lor sit St amet, aiscin elit. Phellus , sit amet
              ruterat libero vitae felis - Dolor j ddjorem ipsuet ruter atlibero
              vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum lor sitit
              amet, aiscin elit. Phellus , sit amet ruterat libero vitae felis -
              Dolor j ddjorem ipsuet ruter atlibero vitae felis - Dolor sit
              amet, aiscin elit.
            </p>
          </div>
        </div>
      </div>
      <div className="text-[#023059] text-xl font-semibold py-10 flex justify-start md:mx-10 mx-3 lg:mx-20 xl:mx-20">
        <p>You may also like</p>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1  xl:gap-0 lg:gap-5 md:gap-9 gap-9 xl:mx-44 lg:mx-14 md:mx-20 mx-4">
        {cardlist.map((elem, index) => (
          <Cards7
            key={index}
            img={elem.image}
            title={elem.title}
            description={elem.description}
            time={elem.time}
            read={elem.read}
          />
        ))}
      </div>
      <div className=" mt-20 flex justify-center items-center w-32 h-10  rounded-md mx-auto bg-[#012443] text-base font-semibold text-[#21FAB4]">
        <button>Load More</button>
      </div>
    </div>
  );
};

export default Article;
