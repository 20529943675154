import React from "react";
import Line2 from "../../assests/Line2.png";
import Cards5 from "../Cards5";
import AI from "../../assests/ai.png";
import Avani1 from "../../assests/avani1.png";
import Avani2 from "../../assests/avani2.png";
import AI2 from "../../assests/ai2.png";
import { Link } from "react-router-dom";

const Avani = () => {
  const cardlist = [
    {
      title: "Services we provide",
      image: AI,
      description:
        "Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet ruteratlibero vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum - Dolor sit",
    },
    {
      title: "Services we provide",
      image: AI2,
      description:
        "Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet ruteratlibero vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum - Dolor sit",
    },
  ];
  return (
    <div className="lg:mx-20 md:mx-10 mx-5 py-2 flex items-center space-x-60 my-10 ">
      <div className="flex-col">
        <div className="flex py-5 text-xs text-[#023059] font-semibold">
          <img
            src={Line2}
            alt="line"
            className="w-18 h-1 mt-2 flex items-center"
          />
          <span className="text-[#C8C8C8]">01.</span> <span>ABOUT AVANI</span>
        </div>
        <div className="text-[#023059] sm:text-4xl text-base font-medium space-y-2 md:mb-10 mb-5 lg:mb-20">
          <p>Here are some great service</p>
          <p>
            {" "}
            with outstanding value through{" "}
            <span className="font-extrabold bg-gradient-to-b from-[#023059] to-[#21FAB4] inline-block text-transparent bg-clip-text">
              {" "}
              AVANI{" "}
            </span>
          </p>
        </div>
        <div className="lg:flex block xl:space-x-[530px] lg:space-x-[250px] ">
          <div className=" sm:w-52 sm:h-32 w-60 h-16 rounded-xl  drop-shadow-xl text-xl font-medium flex justify-center items-center">
            {" "}
           <img src={Avani1} alt="logo" className="md:block hidden w-[350px] h-[250px]" />
           <img src={Avani2} alt="logo" className="md:hidden block  w-[150px] h-[150px]" />
          </div>
          <div className="flex-col  my-5 lg:my-0 space-y-10">
            <div className="text-[#4F4F4F] lg:text-sm md:text-base sm:text-sm text-xs xl:w-[490px] lg:w-[400px] md:w-[650px] w-[280px] font-medium">
              {" "}
              <p>
                Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet
                rutrumhbbbb eratlibero vitae felis - Dolor sit amet, aiscin
                elit. PhasLorem ipsum - Dhu sit amet, aiscin elit. ellus , sit
                amet rutrum eratlibero vitae felis - Dolor sit amet, aiscint
                amet, aiscin elit. ellus , sit amet rutrum eratlibero vitae
                felis - Dolorhh sit amet, aiscin elit. Phasellus , sit amet
                rutrum eratlibero vitae felis
              </p>
            </div>
            <div className="flex justify-center items-center w-32 h-10 md:mx-0 mx-16 py-1 rounded-md px-3 bg-[#012443] text-base font-semibold text-[#21FAB4]">
              <button><Link to="/product2">
                <span>Know More</span>
                </Link>
                              </button>
            </div>
          </div>
        </div>
        <div className="lg:flex block xl:space-x-[200px] lg:space-x-[40px]   items-center  my-20">
          <div className="flex-col space-y-5  items-center ">
            <div className="flex py-2 xl:text-xl lg:text-base md:text-2xl text-base font-bold xl:w-[390px] lg:w-[290px] md:w-[700px] w-[290px] text-[#023059] xl:font-semibold">
              <p>
                Services we provideLorem ipsum - Dolor sit amet, aiscin elit.
                Phasellus , sit amet rutrum eratlibero vitae felis
              </p>
            </div>

            <div className=" text-[#4F4F4F]  py-5 lg:py-0  xl:text-sm  xl:w-[500px] w-[280px] font-medium  sm:text-sm lg:text-sm md:text-base text-xs lg:w-[380px] md:w-[650px] ">
              <p>
                Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet
                rutrum eratlibero vitae felis - Dolorit amet rutrum eratlibero
                vitae felis - Dolor sit amet, aiscint amet, aiscin elit. ellus ,
                sit amet rutrum eratlibero vitae felis - Dolor sit amet, aiscin
                elit. Phasellus , sit amet rutrum eratlibero vitae felis
              </p>
            </div>
          </div>
          <div>
            <iframe
             
              src="https://www.youtube.com/embed/jTX8MSw0Ufw"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              className="flex lg:mx-10 md:mx-1 mx-0 rounded-2xl sm:w-[430px] sm:h-[210px] w-[280px] h-[150px]"
            ></iframe>
          </div>
        </div>

        <div className="xl:flex block  items-center">
          <div className="text-3xl flex-1 xl:w-[200px] lg:w-[400px] md:w-[500px] w-[200px] text-[#023059] flex justify-start font-semibold">
            <span>AVANI is here to makerem ipsum !</span>
          </div>
          <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-col-1 xl:gap-9 gap-10 my-8 justify-center">
            {cardlist.map((elem, index) => (
              <Cards5
                key={index}
                title={elem.title}
                img={elem.image}
                description={elem.description}
              />
            ))}
          <div className="text-base drop-shadow-lg md:mt-14 mx-10 lg:mx-0 md:mx-56 underline underline-offset-1 w-[200px] text-[#023059] flex justify-center items-center font-medium">
            
              <Link><span className="text-center">See More</span></Link>
          
          </div>
          </div>
        </div>
        <div className="text-base text-[#000000] font-medium  xl:mx-80 lg:mx-20 md:mx-5 mx-5 mt-12 text-center flex justify-center">
          <span>
            We welcome opportunities to work alongside different teams over
            projects of any complexity. By working together, we will develop new
            systems , solutions and products
          </span>
        </div>
      </div>
    </div>
  );
};

export default Avani;
