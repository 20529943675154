import React from 'react'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from 'react-router-dom';
const Cards7 = ({title,img,description,time,read}) => {
  return (
    <div>
         <div class="box-content relative h-[450px] w-72  drop-shadow-xl rounded-2xl bg-white  hover:scale-110 duration-300 cursor-default">

      <div className="flex-col  items-center text-center">
        {" "}
          <div className="flex justify-center">
           
            <img src={img} alt="img" className="w-[600px]" />
          </div>
          <div className="text-[#7E7E7E] w-[240px] text-xs  mx-5 font-semibold ">
        <div className=" space-y-3 py-5">
            <p className='text-[#023059] text-lg text-left font-semibold'>{title}</p>
            <p className="text-left mx-2 ">{description}</p>
          </div>
        </div>
      <div className='flex text-[#7E7E7E] justify-center mx-5 space-x-20 text-xs font-semibold '>
        <p>{time}</p>
        <Link><div className='flex  text-[#3284E9]'>
        <p>{read}</p>
            <ArrowBackIcon className="rotate-180 " />
        </div></Link>
      </div>
      </div>
    </div> 
    </div>
  )
}

export default Cards7