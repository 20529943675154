import React from "react";
import Line2 from "../../assests/Line2.png";
import Line3 from "../../assests/Line3.png";

const Deliver = () => {
  return (
    <div>
      <div className="md:mx-20 sm:mx-14 mx-7 lg:mx-20 py-10 flex items-center  my-10 ">
        <div className="flex-col">
          <div className="flex py-5 text-xs text-[#023059] font-semibold">
            <img
              src={Line2}
              alt="line"
              className="w-18 h-1 mt-2 flex items-center"
            />
            <span className="text-[#C8C8C8]">04.</span>{" "}
            <span>HOW IT WORKS</span>
          </div>
          <div className="text-[#023059] md:text-4xl sm:text-3xl text-xl md:w-[500px] font-bold space-y-2 ">
            <p>
              How we deliver{" "}
              <span className="font-semibold">great service with outstanding value </span>{" "}
            </p>
           
          </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 xl:gap-44 lg:gap-24 gap-12 mt-10">
            <div className="flex-col space-y-6 justify-center">
              <div className="flex text-[#023059] text-6xl font-medium">
                <img src={Line3} alt="line" className="w-28 h-1 mt-9 " />
                <p>1</p>
              </div>
              <div>
                <p className="xl:text-base lg:text-sm sm:w-[300px] w-[230px] font-semibold text-[#7E7E7E]">
                  <span className="text-[#023059] text-base"> Lorem ipsum -</span>{" "}
                   Dolor sit amet, aiscin elit. Phasellus fringilla felis
                  tortor, sit amet malesuada neque luctus sed. Pellentesque
                  nisl, sit amet rutrum eratlibero vitae felis
                </p>
              </div>
            </div>
            <div className="flex-col space-y-6">
              <div className="flex text-[#023059] text-6xl font-medium">
                <img src={Line3} alt="line" className="w-28 h-1 mt-9 " />
                <p>2</p>
              </div>
              <div>
                <p className="xl:text-base lg:text-sm sm:w-[300px] w-[230px] font-semibold text-[#7E7E7E]">
                  <span className="text-[#023059] text-base"> Lorem ipsum -</span>{" "}
                  Dolor sit amet, aiscin elit. Phasellus fringilla felis
                  tortor, sit amet malesuada neque luctus sed. Pellentesque
                  nisl, sit amet rutrum eratlibero vitae felis
                </p>
              </div>
            </div>
            <div className="flex-col space-y-6">
              <div className="flex text-[#023059] text-6xl font-medium">
                <img src={Line3} alt="line" className="w-28 h-1 mt-9 " />
                <p>3</p>
              </div>
              <div>
                <p className="xl:text-base lg:text-sm sm:w-[300px] w-[230px] font-semibold text-[#7E7E7E]">
                  <span className="text-[#023059] text-base"> Lorem ipsum -</span>{" "}
                  Dolor sit amet, aiscin elit. Phasellus fringilla felis
                  tortor, sit amet malesuada neque luctus sed. Pellentesque
                  nisl, sit amet rutrum eratlibero vitae felis
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deliver;
