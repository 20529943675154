import React from "react";
import Line2 from "../../assests/Line2.png";
import A from "../../assests/a.png";
import B from "../../assests/b.png";
import HRI1 from "../../assests/HRI1.png";
import HRI2 from "../../assests/HRI2.png";
import Line6 from "../../assests/Line 6.png";
import Hat from "../../assests/hat.png";
import Wellness1 from "../../assests/Wellness1.png";
import Wellness2 from "../../assests/Wellness2.png";

const About = () => {
  return (
    <div className="relative   bg-gradient-to-bl outline-none drop-shadow-xl from-[#ffffff] to-[#ACFFE4] pb-28">
      <div className="absolute md:block hidden top-80 right-0">
        <img src={A} alt="a" />
      </div>
      <div className="md:mx-10 mx-5 lg:mx-20 flex items-center space-x-60 my-10 ">
        <div className="flex-col">
          <div className="flex py-5 text-xs text-[#023059] font-semibold">
            <img
              src={Line2}
              alt="line"
              className="w-18 h-1 mt-2 flex items-center"
            />
            <span className="text-[#C8C8C8]">02 .</span>{" "}
            <span>About MyBizmo</span>
          </div>
          <div className="text-[#023059] md:text-4xl text:3xl font-medium space-y-2 md:mb-20 mb-10">
            <p>Here are some great service with</p>
            <p>
              outstanding value through{" "}
              <span className="font-extrabold bg-gradient-to-b from-[#023059] to-[#21FAB4] inline-block text-transparent bg-clip-text">
                {" "}
                MyBizmo{" "}
              </span>
            </p>
          </div>
          <div className="md:flex block xl:space-x-96 lg:space-x-48 md:space-x-28 ">
            <div className=" w-60 h-28  flex justify-center items-center">
              {" "}
              <img src={Wellness2} alt="logo" className="md:block hidden w-[250px] h-[200px]" />
           <img src={Wellness1} alt="logo" className="md:hidden block  w-[150px] h-[150px]" />
            </div>
            <div className="flex-col justify-center items-center space-y-10">
              <div className="text-[#4F4F4F] text-sm  lg:w-[450px] md:w-[320px] w-[260px] md:mt-0 mt-10 font-medium">
                {" "}
                <p>
                  Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit
                  amet rutrum eratlibero vitae felis - Dolor sit amet sit amet
                  rutrum eratlibero vitae felis - Dolor sit amet, aiscin elit.
                  Phasellus , sit amet rutrum eratlibero vitae felis
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="md:mx-10 mx-5 lg:mx-20 flex items-center space-x-60  ">
        <div className="flex-col">
          <div className="flex py-5 md:mt-28 mt-5 text-xs text-[#023059] font-semibold">
            <img
              src={Line2}
              alt="line"
              className="w-18 h-1 mt-2 flex items-center"
            />
            <span className="text-[#C8C8C8]">04.</span> <span>HRI@Mybizmo</span>
          </div>
          <div className="text-[#023059] md:text-4xl text:3xl font-medium space-y-2 mb-10">
            <p>HRI@Mybizmo</p>
          </div>
          <div className="md:flex block xl:space-x-96 lg:space-x-48 md:space-x-28 md:mb-0 mb-10 justify-end">
            <div className="flex-col justify-center items-center ">
              <div className="text-[#4F4F4F] text-sm  lg:w-[450px] md:w-[320px] md:mb-0 mb-10 w-[260px] font-medium">
                {" "}
                <p>
                  Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit
                  amet rutrum eratlibero vitae felis - Dolor sit amet sit amet
                  rutrum eratlibero vitae felis - Dolor sit amet, aiscin elit.
                  Phasellus , sit amet rutrum eratlibero vitae felis
                </p>
              </div>
            </div>
            <div className="absolute md:block hidden  md:top-[575px] top-[745px] z-10 xl:right-[480px] lg:right-[250px] md:right-[250px] right-[270px]">
              <img src={Hat} alt="hat" />
            </div>
            <div className=" text-center border-2 w-60 h-28 drop-shadow-xl rounded-2xl bg-black text-2xl font-medium flex justify-center items-center">
              {" "}
              <img src={HRI2} alt="logo" className="md:block hidden w-[350px] h-[120px] rounded-2xl" />
           <img src={HRI1} alt="logo" className="md:hidden block  w-[250px] h-[150px] rounded-2xl" />
            </div>
          </div>
          <div className="flex drop-shadow-2xl justify-center items-center w-32 h-10 mx-16 md:mx-0  py-1 rounded-md px-3 bg-[#012443] text-base font-semibold text-[#21FAB4]">
            <button>
              <span>Know More</span>
            </button>
          </div>
        </div>
      </div>
      <div className="absolute md:block hidden xl:bottom-[330px] lg:bottom-[300px] md:bottom-[450px]  xl:left-[500px] lg:left-[440px] md:left-[330px]">
        <img src={B} alt="a" />
      </div>

      <div className=" mt-36 md:mx-10 mx-5 my-10 xl:mx-10 lg:mx-10 grid lg:grid-cols-4 md:grid-cols-2 xl:gap-20 lg:gap-80 md:gap-14 gap-16">
                <div className="lg:flex md:hidden block absolute xl:bottom-44 lg:bottom-40 bottom-64  text-5xl font-extrabold w-[290px] xl:space-x-[280px] space-x-0 text-[#CFE5F2] text lg:space-x-[240px] md:space-y-[110px]  space-y-[120px]">
                <p className="lg:my-24 my-0">01.</p>
                <p>02.</p>
                <p>03.</p>
              </div>
      {Array(3)
            .fill()
            .map((_, index) => (
                <>
              <div className="flex items-center text-sm text-black md:w-[290px] w-[250px] xl:space-x-10 lg:space-x-5 md:space-x-10 font-medium ">
                <p>
                  {" "}
                  Lorem ipsum -
                  <span className="text-[#454545]">
                    Dolor sit amet, aiscin elit. Phasellus , sit amet rutrum
                    eratlibero vitae felis - Dolor sit amet, aiscin elit. PhasLorem
                    ipsum - Dolor sit amet,
                  </span>
                </p>
                <img src={Line6} alt="line"  className={index===2 &&`hidden` || index===1 &&`md:block hidden` || index===0 &&`md:block hidden` } />
              </div>
              </>
              ))}
      
      </div>
    </div>
  );
};

export default About;
