import React from "react";
import Cards6 from "../Cards6";
import AI from "../../assests/ai.png";
import AI2 from "../../assests/ai2.png";
import { Link } from "react-router-dom";

const OurProduct = () => {
  const cardlist = [
    {
      title: "Yoga Pose Detection  ",
      image: AI,
      description:
        "Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet ruteratlibero vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum - Dolor sit",
      button: "Test Application",
      link:"/yoga"
    },
    {
      title: "Smoke test Detection",
      image: AI2,
      description:
        "Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet ruteratlibero vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum - Dolor sit",
      button: "Test Application",
      link:"/smoking"
    },
    {
      title: "Services we provide",
      image: AI,
      description:
        "Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet ruteratlibero vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum - Dolor sit",
      button: "Test Application",
      link:""
    },
    {
      title: "License plate Detection ",
      image: AI,
      description:
        "Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet ruteratlibero vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum - Dolor sit",
      button: "Test Application",
      link:"/license"
    },
    {
      title: " Local language extraction",
      image: AI2,
      description:
        "Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet ruteratlibero vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum - Dolor sit",
      button: "Test Application",
      link:"/language"
    },
    {
      title: "Services we provide",
      image: AI,
      description:
        "Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet ruteratlibero vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum - Dolor sit",
      button: "Test Application",
      link:""
    },
  ];
  return (
    <div>
    <div className="lg:flex block  justify-center bg-gradient-to-b opacity-100 outline-none py-5 from-[#ffffff] to-[#ACFFE4]">
      <div className="xl:mx-20 lg:mx-10 md:mx-20 mx-3 text-center">
        <div className="flex justify-center  mb-5 text-3xl text-[#023059] font-semibold ">
          <p>Our Products</p>
        </div>
        <div className="text-base mb-20 mx-auto text-center text-[#7E7E7E] md:w-[600px] w-[280px] font-medium">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
            frietur adipiscing elit. Phasellus fringilla felis tortor, sitet
            rutrum erat libero vitae fel
          </p>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 xl:gap-20 lg:gap-10 md:gap-10 gap-10">
          {cardlist.map((elem, index) => (
           
           <Cards6
              key={index}
              title={elem.title}
              img={elem.image}
              description={elem.description}
              link={elem.link}
              button={elem.button}
            />
          ))}
        </div>
        <div className="flex mx-auto  mt-20 justify-center items-center w-32 h-10  rounded-md px-3 bg-[#012443] text-base font-semibold text-[#21FAB4]">
          <button>
            <Link>
              <span>See More</span>
            </Link>
          </button>
        </div>
        <div className="text-[#000000]  mt-10 opacity-75 mx-auto text-xs font-medium md:w-[450px] w-[290px]">
          <p>
            We welcome opportunities to work alongside different teams over
            projects of any complexity. By working together, we will develop new
            systems , solutions and products
          </p>
        </div>
      </div>
    </div>
    <div className='py-28 bg-gradient-to-t from-[#ffffff] to-[#ACFFE1]'>

    </div>
    </div>
  );
};

export default OurProduct;
