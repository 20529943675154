import React, { useEffect } from 'react'
import Discover from '../components/Discover'
import About from '../components/Home/About'
import Footer from '../components/Footer'
import Header from '../components/Home/Header'
import Nav from '../components/Nav'
import Image1 from "../assests/image1.png"
import Benifits from '../components/Home/Benifits'
import Highlights from '../components/Home/Highlights'
import Deliver from '../components/Home/Deliver'
import Testimonials from '../components/Home/Testimonials'
import Sidebar from '../components/Sidebar'
import { useLocation } from 'react-router-dom'


const LandingPage = () => {
  const routePath = useLocation();
  useEffect(()=>{
  window.scrollTo(0,0);
},[routePath.pathname])
// console.log(routePath.pathname)

  return (
    <div>
        <Nav/>
        <Sidebar/>
        <Header
        image={Image1}
         description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus fringilla felis tortor, sitet rutrum erat libero vitae felis"/>
        <About/>
        <Benifits/>
        <Highlights/>
        <Deliver/>
        <Testimonials/>
        <Discover/>
        <Footer/>
    </div>
  )
}

export default LandingPage