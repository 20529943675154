import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Nav from "../components/Nav";
import Sidebar from "../components/Sidebar";
import Cookie from 'cookie-universal'
import Text from "../components/Text";
import TextField from "../components/TextField";
import { toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import { base_url } from "../utils/base_URL";
// import TextonTop from "../components/TextonTop";

const CheckMail = () => {
  const cookiesFPC = Cookie()
  const OTP = cookiesFPC.get("otp");
  const[otp,setOtp]=useState("")
  const[error,setError]=useState("")
  const Verification_mail=localStorage.getItem("email")
  const navigate=useNavigate()

  //console.log(EMAIL)
  useEffect(() => {
    
    toast.success(`your OTP is:${OTP}`, {
      position: "top-right",
      autoClose: true,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }, []);

  
  const handleFPasswordOTP = () => {
    setError("");
    if (otp.length > 0) {
      const requestBody = {
       
    email:Verification_mail,
    otp:otp

      };
      axios
        .post(`${process.env.SERVER_URL || base_url}auth/user/forgot/password/verify`, requestBody, {
          headers: {
            Authorization: "Token " + cookiesFPC.get("token"),
          },
        })
        .then(function (response) {
          console.log("successful Entered OTP ", response.data);
          if (response?.request?.status === 400)
          {
           navigate("/checkmail")
           
          }
          else if(response?.request?.status === 200)
          {
            navigate("/newpass")
          }
        })
        .catch(function (error) {
          console.log("ERROR IN Entered OTP ", error);
          if (error?.response?.data?.user) {
            setError("Something went wrong");
          }
          else if (error?.response?.data?.error)
          setError("The verification OTP entered does not match")
        });
    } else {
      setError("You have not entered the OTP sent");
    }
  };
// console.log(Verification_mail)
  return (
    <>
    {" "}
    <Nav />
    <Sidebar />
    <div className="bg-[#023059] pb-40 pt-14 ">
      <div className="box-content bg-[#e6e8ef16] xl:w-[1100px] lg:w-[800px] md:w-[600px] w-[300px] mx-auto py-36 h-[400px] rounded-xl">
        <Text
          title="Verify your OTP"
          description={`We sent a password reset OTP to
          ${Verification_mail}`}
        />

        <div className="md:w-[500px] w-200px space-y-8 mt-8">
          <TextField type="number" placeholder="OTP" value={otp} onChange={(e)=>{
            setOtp(e.target.value)
          }}/>

          <Button buttontext="Verify" onClick={handleFPasswordOTP}/>
          {error.length > 0 && (
                <div className="flex justify-center items-center font-semibold text-white text-base xl:mx-[290px] lg:mx-[170px] md:mx-[50px] mx-[5px] text-center w-full">
                  <span>{error}</span>
                </div>
              )}
          <div className='flex justify-end items-center space-x-2 px-5 py-2 w-full xl:mx-[200px] lg:mx-[80px] md:-mx-[35px] -mx-[10px]'>
            <div className='text-[#FFFFFF] md:text-base text-xs font-semibold'>
                <p>Don’t receive the OTP ?</p>
            </div>
            <div className='text-[#21FAB4] md:text-base text-xs font-semibold'>
          <Link to="/forgetpass">  <p>Click to resend</p> </Link>
            </div>
            </div> 

          <div className="text-[#21FAB4AD]  text-sm xl:mx-[280px] lg:mx-[160px] md:mx-[40px] mx-[20px] my-7 sm:w-[500px] w-[250px] text-center">
            <Link to="/signin">
            <div className="flex space-x-4 justify-center">

              <ArrowBackIcon />
              <p>Back to Sign In</p>
            </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default CheckMail