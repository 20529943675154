import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Discover from "../components/Discover";
import Footer from "../components/Footer";
import Header from "../components/HeaderLanguage";
import Nav from "../components/Nav";
import Languages from "../assests/language1.png";
import Languages2 from "../assests/language2.png";
import Sidebar from "../components/Sidebar";
import CardsComponent from "../components/CardsComponent";
import UploadImage from "../components/UploadImage";

const Language1 = () => {
    const routePath = useLocation();
    useEffect(()=>{
    window.scrollTo(0,0);
  },[routePath.pathname])
  return (
    <div>
      <div>
        <Nav />
        <Sidebar />
        <Header
          image1={Languages}
          image2={Languages2}
          video="https://www.youtube.com/embed/jTX8MSw0Ufw"
        />
        <UploadImage/>
        <CardsComponent />
        <Discover />
        <Footer />
      </div>
    </div>
  );
};

export default Language1;
