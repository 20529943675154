import React, { useState } from "react";
import Button from "../components/Button";
import Nav from "../components/Nav";
import Sidebar from "../components/Sidebar";
import Text from "../components/Text";
import TextField from "../components/TextField";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { base_url } from "../utils/base_URL";
import axios from "axios";
import Cookie from "cookie-universal";


const NewPass = () => {
  const [newPass, setNewPass] = useState("");
  const [newCPass, setNewCPass] = useState("");
  const navigate=useNavigate()
  const [error, setError] = useState("");
  const cookies = Cookie();
  const handleResetPassword = () => {
    if (newPass.length > 0 && newCPass.length > 0) {
      if(newPass.length >= 8 && newCPass.length >= 8){
      
        
        if (newPass === newCPass) {
          const requestBody = {
            password: newPass,
          };
          axios
            .post(`${process.env.SERVER_URL || base_url}auth/user/reset/password`, requestBody, {
              headers: {
                Authorization: "Token " + cookies.get("token"),
              },
            })
            .then(function (response) {
              console.log("successful RESPONSE ", response.data);
              if (response?.request?.status === 200) {
                if(response?.data?.message.toString()==="You have Successfully Changed your password"){
                  navigate("/setnewpass")
                  //setErrors(response?.data?.message)
                }
                else if(response?.data?.message)
                {
                 navigate("/newpass")
                  setError(response?.data?.message)
                }
                //console.log("java");
              }
            })
            .catch(function (error) {
              console.log("ERROR RESPONSE ", error);
              if (error?.response?.data?.user) {
                setError("Something went wrong");
              }
            });
        } else {
          setError("Password and Confirm password is not same");
        }
      }
        else{
          setError("Ensure password and Confirm Password have at least 8 characters.")
        }
    }
     else {
      setError("Please fill the required field");
    }
  };

  return (
    <>
    {" "}
    <Nav />
    <Sidebar />
    <div className="bg-[#023059] pb-40 pt-14 ">
      <div className="box-content bg-[#e6e8ef16] xl:w-[1100px] lg:w-[800px] md:w-[600px] md:py-36 py-20 w-[300px] h-[400px] md:h-[500px] mx-auto rounded-xl">
       
        <Text
          title="Set new password"
          description="Your new password must be different to previously used password."
        />

        <div className="md:w-[500px] w-200px space-y-8 mt-8">
          <TextField type="password" placeholder="Password" value={newPass}
            onChange={(e) => {
              setNewPass(e.target.value);
            }}/>
          <TextField type="password" placeholder="Confirm Password" value={newCPass}
            onChange={(e) => {
              setNewCPass(e.target.value);
            }} />

          {/* <div className="flex items-center my-5 xl:mx-[50px] lg:mx-[410px] md:mx-[290px] mx-10 text-center space-x-52">
            <div className="flex items-center">
              <Switch defaultChecked />
              <span className="text-[#21FAB4] w-full">Remember me</span>
            </div>
            <span className="text-red-600 w-[200px]">Recover Password</span>
          </div> */}

          <Button buttontext="Reset Password" onClick={handleResetPassword} />
          {error.length > 0 && (
                <div className="flex justify-center items-center font-semibold text-white text-base xl:mx-[290px] lg:mx-[170px] md:mx-[50px] mx-[5px] text-center w-full">
                  <span>{error}</span>
                </div>
              )}
          <div className="text-[#21FAB4AD]  text-sm xl:mx-[280px] lg:mx-[160px] md:mx-[40px] mx-[20px] my-7 sm:w-[500px] w-[250px] text-center">
            <Link to="/signin">
            <div className="flex space-x-4 justify-center">

              <ArrowBackIcon />
              <p>Back to Sign In</p>
            </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default NewPass