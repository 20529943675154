// require('dotenv').config()
import React from "react";
import Button from "../components/Button";
import Button2 from "../components/Button2";
import Nav from "../components/Nav";
import Sidebar from "../components/Sidebar";
import Text from "../components/Text";
import { blueGrey } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
import TextField from "../components/TextField";
import TextonTop from "../components/TextonTop";
import { useState } from "react";
import axios from "axios";
import Cookie from 'cookie-universal'
import { base_url } from "../utils/base_URL";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const navigate=useNavigate()
  const cookies = Cookie()
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState("");
  // console.log("name:",name)
  // console.log("email:",email)
  // console.log("password",password)
  // console.log("cpassword",cpassword)
  const handleSignup = (event) => {
    setError("");

    if (
      name.length > 0 &&
      email.length > 0 &&
      password.length > 0 &&
      cpassword.length > 0
    ) {
      if (password === cpassword) {
        if (checked) {
          const requestBody = {
            email: email,
            password: password,
          };
          axios
            .post(
              `${process.env.SERVER_URL || base_url}auth/user/register`,
              requestBody,
              {
                //  headers:""
              }
            )
            .then(function (response) {
              console.log(response);
              if (response?.request?.status === 400) {
                navigate("/signup")
               } else if (response?.request?.status === 200) {
                 navigate("/signin")
                 cookies.set('token',response?.data?.token)
                 cookies.set('otp',response?.data?.otp)
                 //cookies.set('password',password)
               }
            })
            .catch(function (error) {
              console.log(error);
              if (
                error?.response?.data?.errors?.email ||
                error?.response?.data?.errors?.password
              ) {
                if (error?.response?.data?.errors?.email?.toString()==="user with this email already exists.") {
                  setError(error?.response?.data?.errors?.email?.toString());
                }
                else if(error?.response?.data?.errors?.email?.toString()==='Enter a valid email address.')
                {
                  setError(error?.response?.data?.errors?.email?.toString()); 
                }
                else if (error?.response?.data?.errors?.password) {
                  setError("Ensure password has at least 8 characters.");
                }
              } 
            });
        } else {
          setError("Please click on the CheckBox and then Continue");
        }
      } else {
        setError("password and confirm password is not same");
      }
    } else {
      setError("Required field is empty");
    }
  };
  // console.log(error);

  const handleChange = (event) => {
    // setError=("")
    setChecked(event.target.checked);
  };
  
  // console.log(error);
  // console.log(error.length);

  return (
    <>
      {" "}
      <Nav />
      <Sidebar />
      <div className="bg-[#023059] pb-40 pt-14 ">
        <div className="box-content bg-[#e6e8ef16] xl:w-[1100px] lg:w-[800px] md:w-[600px] w-[300px] mx-auto  h-[900px] rounded-xl">
          <TextonTop
            title1="Have an account?"
            title2="Sign in!"
            link="/signin"
          />
          <Text
            title="Get Started With Karmaa Lab"
            description="Getting started is easy"
          />

          <div className="md:w-[500px] w-200px space-y-8 mt-8">
            <TextField
              type="text"
              placeholder="Full Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <TextField
              type="email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <TextField
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <TextField
              type="password"
              placeholder="Confirm Password"
              value={cpassword}
              onChange={(e) => {
                setCPassword(e.target.value);
              }}
            />
            <div className="text-[#B0B0B0] flex items-center text-sm xl:mx-[280px] lg:mx-[170px] md:mx-[50px] mx-[24px] my-7 sm:w-[500px] w-[250px] text-center">
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  color: blueGrey[200],
                  "&.Mui-checked": {
                    color: blueGrey[200],
                  },
                }}
              />
              <p>
                By continuing you indicate that you read and agreed to the Terms
                of Use
              </p>
            </div>
            {checked ? (
              <Button buttontext="Create Account" onClick={handleSignup} />
            ) : (
              <Button buttontext="Create Account" onClick={handleSignup} />
            )}
            {error.length > 0 && (
              <div className="flex justify-center items-center font-semibold text-white text-base xl:mx-[290px] lg:mx-[170px] md:mx-[50px] mx-[5px] text-center w-full">
                <span>{error}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className="my-5 xl:mx-[550px] lg:mx-[410px] md:mx-[290px] mx-10 text-center text-[#21FAB4] text-xl font-semibold">
              <p>or</p>
            </div>
            <Button2 /> */}
    </>
  );
};

export default Signup;
