import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import Header from '../components/AboutUs/Header'
import Nav from '../components/Nav'
import Image2 from "../assests/image2.png"
import TeamMembers from '../components/AboutUs/TeamMembers'
import Vision from '../components/AboutUs/Vision'
import Deliver from '../components/AboutUs/Deliver'
import Sidebar from '../components/Sidebar'
import { useLocation } from 'react-router-dom'

const About_us = () => {
  const routePath = useLocation();
  useEffect(()=>{
  window.scrollTo(0,0);
},[routePath.pathname])
  return (
    <div>
        <Nav/>
        <Sidebar/>
        <Header
         image={Image2}
         description="Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Phasellus fringilla felis tortor, sitet rutrum erat libero vitae felis lorem ipsum dolor sit amet,
           consectetur adipiscing elit. Phasellus fringilla felis tortor, sitet rutrum erat libero vitae felis"/>
<Deliver/> 
 <Vision/>
<TeamMembers/>
        <Footer/>
    </div>
  )
}

export default About_us