import React from "react";
import { Link } from "react-router-dom";

const Button = ({ buttontext, link, onClick }) => {
  return (
  
      <div className="flex justify-center items-center xl:w-full lg:w-[453px]  md:w-[400px] w-[200px] h-10 xl:mx-72 lg:mx-48 md:mx-24 mx-14 md:py-7 py-3 rounded-md px-3 bg-[#21FAB4] md:text-xl text-base font-semibold text-[#012443]" onClick={onClick}>
        <Link to={link}>
          <button className="">
            <span>{buttontext}</span>
          </button>
        </Link>
      </div>
   
  );
};

export default Button;
