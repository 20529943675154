import React, { useState } from "react";
import Doublequotes from "../assests/Vector.png";
//import Brightness1Icon from '@mui/icons-material/Brightness1';
import Linkedin from "../assests/Linkedin1.png";
import Insta from "../assests/Insta1.png";
import Facebook from "../assests/Facebook1.png";
import Twitter from "../assests/twitter.png";

const Cards2 = ({ img, title, about, description }) => {
    const[show,setShow]=useState(false);

  return (
    <div>
      <div className="relative box-content sm:h-72 sm:w-72 h-72 w-64 p-4 drop-shadow-xl rounded-2xl bg-white  hover:scale-110 duration-300 cursor-pointer"onMouseOver={()=>{setShow(true)}} onMouseOut={()=>{setShow(false)}}>
       <div className={`absolute box-content border-2 top-0 -ml-4 rounded-br-3xl bg-[#D8ECFF] w-12 h-10 ${show===false && "hidden"}`}></div>
        <div className="flex-col text-center">
          {" "}
          <div className=" space-y-2 py-9  ">
            <div className="flex justify-center">
              <img src={img} alt="img" className="w-16 h-16 " />
            </div>
            <div className="text-[#023059] w-[270px] text-base space-y-2 font-semibold ">
              <p>{title}</p>
              <p className="text-xs text-[#B8DDFD]">{about}</p>
              <p className="text-xs ">{description}</p>
            </div>
          </div>
        </div>
        <div className={`flex space-x-8 justify-center ${show===false && "hidden"}`} >
        <img
          src={Linkedin}
          alt="dq"
          className="flex w-5 h-5"
        />
        <img
          src={Twitter}
          alt="dq"
          className="flex w-5 h-5"
        />
        <img
          src={Facebook}
          alt="dq"
          className="flex  w-5 h-5"
        />
        <img
          src={Insta}
          alt="dq"
          className="flex w-5 h-5"
        />

        </div>
      </div>
    </div>
  );
};

export default Cards2;
