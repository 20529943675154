import React from "react";
import Accordian from "./Accordian";
const Content = () => {
  const accor = [
    {
      id: 1,
      title:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus frietur adipiscing elit. Phasellus fringilla felis tortor, sitet rutrum erat libero vitae felLor Phasellus fringilla felis tortor, sitet rutrum erat libero vitae fel",
    },
    {
      id: 2,
      title:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus frietur adipiscing elit. Phasellus fringilla felis tortor, sitet rutrum erat libero vitae felLor Phasellus fringilla felis tortor, sitet rutrum erat libero vitae fel",
    },
    {
      id: 3,
      title:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus frietur adipiscing elit. Phasellus fringilla felis tortor, sitet rutrum erat libero vitae felLor Phasellus fringilla felis tortor, sitet rutrum erat libero vitae fel",
    },
    {
      id: 4,
      title:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus frietur adipiscing elit. Phasellus fringilla felis tortor, sitet rutrum erat libero vitae felLor Phasellus fringilla felis tortor, sitet rutrum erat libero vitae fel ",
    },
    {
      id: 5,
      title:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus frietur adipiscing elit. Phasellus fringilla felis tortor, sitet rutrum erat libero vitae felLor Phasellus fringilla felis tortor, sitet rutrum erat libero vitae fel",
    },
  ];
  return (
    <div className=" py-10  xl:mx-36 lg:mx-20 md:mx-20 mx-5">
      <div className="flex justify-center text-3xl text-[#023059] text-center font-semibold">
        <p>Frequently Asked Questions</p>
      </div>
      <div className="text-[#1d191994] mb-5 flex justify-center text-center py-5">
        <p className=" md:w-[550px] w-[300px]">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
          frietur adipiscing elit. Phasellus fringilla felis tortor, sitet
          rutrum erat libero vitae fel
        </p>
      </div>
      <div>
        <div className=" xl:w-[1200px] lg:w-[850px] md:w-[600px] py-5 space-y-10">
        {accor.map((elem, index) => (
            <Accordian
            key={index}
              title={elem.title}
              description={elem.description}
            />  ))}
        </div>
      </div>
    </div>
  );
};

export default Content;
