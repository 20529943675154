import React, { useState } from "react";
import Upload from "../assests/upload.png";

const UploadImage = () => {
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");

//   console.log(file);
//   console.log(fileName?.toString()?.slice(0,20));

  const [isImageSelected, setIsImageSelected] = useState(false);
  const [imagePreviewSrc, setImagePreviewSrc] = useState("");
  console.log(imagePreviewSrc);
  const showImagePreview = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name.toString().slice(0,18));
    let selectedFile = e.target.files.item(0);
    if (selectedFile) {
      if (
        ["image/jpeg", "image/png", "image/svg+xml"].includes(selectedFile.type)
      ) {
        let fileReader = new FileReader();
        fileReader.readAsDataURL(selectedFile);
        fileReader.addEventListener("load", (event) => {
          setImagePreviewSrc(event.target.result);
          setIsImageSelected(true);
        });
      }
    } else {
      setIsImageSelected(false);
    }
  };
  return (
    <div className="bg-[#ACFFE4] my-16 opacity-100 outline-none py-5">
        {isImageSelected === true && imagePreviewSrc != "" ? (
              <div className="box-content bg-white rounded-xl flex-col lg:w-[900px] md:w-[700px] w-[280px] md:h-[250px] lg:h-[400px] mx-auto my-20 md:py-28 py-20">
        <p className="text-[#023059] text-center lg:py-5 md:-py-10 -py-20 font-bold">OUTPUT:</p>
            <div className="flex justify-center items-center">

            {" "}
            <img
              src={imagePreviewSrc}
              alt="preview"
              className="lg:h-[300px] md:h-[250px] h-[200px] object-contain"
            />
          </div>
          <div className="flex justify-center lg:pt-10 md:pt-5 pt-10 items-center w-full">
          <button className=" lg:w-[700px] md:w-[400px] w-[200px] h-10  py-1 rounded-md px-3 bg-[#012443] text-base font-semibold text-[#21FAB4]">
            <span>{fileName.length === 0 ? "Upload Image" : fileName}</span>
          </button>
        </div>
          </div>
        ) : (
            <div className="box-content bg-white rounded-xl flex-col lg:w-[900px] md:w-[700px] w-[280px] md:h-[250px] lg:h-[400px] mx-auto my-20 md:py-28 py-20">
          <div className="border-dashed border-[#023059] border-2 lg:w-[700px] md:w-[400px] w-[200px]  md:h-[250px] lg:h-[300px] py-20 mx-auto  ">
            <div className="box-content mx-auto  p-5 bg-[#E7F2FC] border-2 rounded-full w-10 ">
              {" "}
              <input
                id="file-upload"
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                onChange={(e) => {
                  showImagePreview(e);
                }}
              />
              <label htmlFor="file-upload">
                <img src={Upload} alt="upload" className="w-10 h-10" />
              </label>
            </div>

            <p className="text-xl font-bold text-center  text-[#023059]">
              Select a jpg or png image
            </p>
            <p className="text-sm font-medium text-center text-[#7E7E7E]">
              or drag and drop it here
            </p>
          </div>
        <div className="flex justify-center lg:pt-10 md:pt-5 pt-10 items-center w-full">
          <button className=" lg:w-[700px] md:w-[400px] w-[200px] h-10  py-1 rounded-md px-3 bg-[#012443] text-base font-semibold text-[#21FAB4]">
            <span>{fileName.length === 0 ? "Upload Image" : fileName}</span>
          </button>
        </div>
        </div>
        )}
      </div>
  );
};

export default UploadImage;
