import React from "react";
import Linkedin from "../assests/Linkedin.png";
import Insta from "../assests/instagram.png";
import Logo from "../assests/Logo.png";
import Line from "../assests/Line.png";
import Arrow from "../assests/arrow.png";
import Facebook from "../assests/facebook.png";

const Footer = () => {
  return (
    <div className="bg-[#012443]">
      <div className="py-10 lg:mx-20  md:mx-10 mx-5">
        <div className="md:flex block  md:justify-start items-center ">
          <div className="flex-col my-8 flex-1 justify-center items-center ">
            <img src={Logo} alt="logo" className="object-contain w-44 h-20" />
            <p className="text-[#DEDEDE] xl:text-sm text-xs md:w-[250px] lg:w-[270px]  xl:w-[300px] w-[260px]">
              Neque porro quisquam est qui dolorem ipsum
           
              quia dolor sit amet, consectetur, adipisci.
            </p>
          </div>

          <div className=" flex-col justify-center items-center my-8">
            <p className="text-[#D9D9D9] text-lg font-semibold">
              Interested In working with us ?
            </p>
            <div className=" flex my-5 sm:space-x-3 space-x-0 rounded-3xl xl:px-10 lg:px-7 px-3 xl:w-[500px] lg:w-[450px] md:w-[400px] sm:w-[350px] w-[280px] py-3  bg-gradient-to-r from-slate-500 to-sky-800">
              <input type="text" placeholder="your email address..." className="bg-transparent flex-1 font-medium outline-none" />
              <div className="flex justify-center items-center space-x-2 py-2 rounded-xl px-4 bg-[#012443] sm:text-base text-xs font-bold text-[#21FAB4]">
              <button>Send</button>
              <img src={Arrow} alt="arrow" className="w-3 h-3 flex" />
              </div>
            </div>
          </div>
        </div>

        <div className="lg:mx-20  md:mx-10 mx-3 flex justify-center items-center mb-5">
          <img src={Line} alt="line" className="flex-1" />
        </div>

        <div className="flex text-[#DEDEDE] text-xs justify-between lg:mx-20  md:mx-10 mx-3 ">
          <p>2022 Karmaa Lab. All rights reserved.</p>
          <div className="flex space-x-3 items-center">
            <img src={Facebook} alt="facebook" />
            <img src={Insta} alt="insta" />
            <img src={Linkedin} alt="linkedin" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
