import React from "react";
import Logo from "../assests/Logo.png";
import Logo1 from "../assests/logo1.png";
import Profile from "../assests/ProfileCircle.png";
import { Link } from "react-router-dom";
// import MenuIcon from '@mui/icons-material/Menu';
// import { useDispatch, useSelector } from "react-redux";
// import { hide, selectHeader, show } from "../slices/HeaderSlice";
const Nav = () => {
  // const dispatch=useDispatch();
  // const currentState=useSelector(selectHeader)
  // console.log(currentState)
  // const handleSidebar=()=>{
  //   dispatch(show({
  //     show: true
  //   }))

  //   if(currentState?.show === true) {
  //     dispatch(hide())
  //   }
  // console.log("hello")
  // }
  
  return (
    
      <div className="bg-[#023059] sticky top-0 z-50">
        <div className="flex items-center justify-between md:mx-10 mx-5 lg:mx-20">
          <div ><Link to="/">
            <img src={Logo} alt="logo" className="object-contain   w-44 h-24 " />
            {/* <img src={Logo1} alt="logo" className="object-contain w-44 h-20 md:hidden block " /> */}
            </Link> </div>
          <div className="lg:flex justify-end items-center hidden  space-x-14 text-[#DEDEDE] text-sm font-semibold">
            <p>
              {" "}
              <Link to="/" className="hover:text-white hover:underline underline-offset-8 decoration-2 decoration-[#21FAB4]">Home</Link>{" "}
            </p>
            <p>
              <Link to="/aboutus" className=" hover:text-white hover:underline underline-offset-8 decoration-2 decoration-[#21FAB4]">About us</Link>
            </p>
            <p>
              <Link to="/product1" className=" hover:text-white hover:underline underline-offset-8 decoration-2 decoration-[#21FAB4]">Products</Link>
            </p>
            <p>
              <Link  className=" hover:text-white hover:underline underline-offset-8 decoration-2 decoration-[#21FAB4]">Blogs</Link>
            </p>
            <p>
              <Link to="/contact" className=" hover:text-white hover:underline underline-offset-8 decoration-2 decoration-[#21FAB4]">Contact us</Link>
            </p>
           
            <Link to="/signin" className=" hover:text-white underline">
              {" "}
              <img src={Profile} alt="ques" className="w-8 h-8" />
            </Link>
          </div>
          {/* <div className="lg:hidden block" onClick={handleSidebar}>
            <MenuIcon className="text-white text-2xl"/>
          </div> */}
        </div>
      </div>
    
  );
};

export default Nav;
