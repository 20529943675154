
import React from "react";
import Line2 from "../../assests/Line2.png";
import Cards4 from "../Cards4";
import Speed from "../../assests/speed.png";

const Deliver = () => {
    const cardlist = [
        {
          id:1,
          image: Speed,
          title: "Benefit",
          description:
            "Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet rutrum eratlibero vitae felis",
        },
        {
        id:2,
            image: Speed,
            title: "Benifit",
            description:
              "Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet rutrum eratlibero vitae felis",
          },
          {
            id:3,
            image: Speed,
            title: "Benifit",
            description:
              "Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet rutrum eratlibero vitae felis",
          },
         
      ];
  return (
    <div>
      <div className=" xl:mx-20 lg:mx-20 md:mx-10 mx-3 py-10 flex-col items-center my-10  ">
        <div className="flex-col">
          <div className="flex py-5 text-xs text-[#023059] font-semibold">
            <img
              src={Line2}
              alt="line"
              className="w-18 h-1 mt-2 flex items-center"
            />
            <span className="text-[#C8C8C8]">01.</span>{" "}
            <span> HOW CHOOSE US ?</span>
          </div>
          <div className="text-[#023059] md:text-4xl sm:text-3xl text-xl md:w-[500px] ">
            <p className="font-bold" >
              How we deliver{" "}
              <span className="font-semibold">great service </span>{" "}
            </p>
            <p>
              <span className="font-semibold"> with outstanding value</span>
            </p>
          </div>
        <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-col-1 xl:gap-10 lg:gap-20 md:gap-20 gap-10 my-8 justify-center">
            {
                cardlist.map((elem,index)=>(
                    <Cards4
                    key={index}
                    id={elem.id}
                    img={elem.image}
                    title={elem.title}
                    description={elem.description}/>
                ))
            }
        </div>
        </div>
      </div>
    </div>
  );
};

export default Deliver;
