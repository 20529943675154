import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import Nav from '../components/Nav'
import Header from '../components/Products/Header'
import Image3 from "../assests/image3.png"
import Discover from '../components/Discover'
import Avani1 from '../components/Products/Avani1'
import About from '../components/Products/About'
import HRI from '../components/Products/HRI'
import Sidebar from '../components/Sidebar'
import { useLocation } from 'react-router-dom'

const Product = () => {
  const routePath = useLocation();
  useEffect(()=>{
  window.scrollTo(0,0);
},[routePath.pathname])
  return (
    <div>
        <Nav/>
        <Sidebar/>
        <Header
          image={Image3}
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit.
           Phasellus fringilla felis tortor, sitet rutrum erat libero vitae felis lorem ipsum dolor sit amet,
            consectetur adipiscing elit. 
          Phasellus fringilla felis tortor, sitet rutrum erat libero vitae felis"/>
          <Avani1/>
          <About/>
          <HRI/>
          <Discover/>
        <Footer/>
    </div>
  )
}

export default Product