import React from "react";
import Doublequotes from "../assests/Vector.png";

const Cards1 = ({ img, title, about, description }) => {
  return (
    <div>
      <div class="relative box-content sm:h-72 h-64 w-60 sm:w-72 p-4 drop-shadow-xl rounded-tl-2xl bg-white hover:border-blue-200 hover:border-2 hover:scale-110 duration-300 cursor-pointer">
        <img
          src={Doublequotes}
          alt="dq"
          className="absolute object-scale-down top-0 left-0 -mt-3 -ml-3 w-10 h-10"
        />
        <div className="flex-col text-center sm:mx-9 mx-2">
          {" "}
          <div className=" space-y-2 py-12">
            <div className="flex justify-center">
              <img src={img} alt="img" className="w-16 h-16 " />
            </div>
            <div className="text-[#023059] w-[220px] text-base space-y-2 font-semibold ">
              <p>{title}</p>
              <p className="text-xs text-[#B8DDFD]">{about}</p>
              <p className="text-xs ">{description}</p>
            </div>
        <img
          src={Doublequotes}
          alt="dq"
          className="flex object-scale-down object-right-bottom mx-[230px] absolute bottom-0 rotate-180 w-5 h-5"
        />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards1;
