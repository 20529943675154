import React from 'react'
import { Link } from 'react-router-dom'

const TextonTop = ({title1,title2,link}) => {
  return (
    <div>
          <div className='flex md:justify-end justify-center items-center space-x-2 px-5 py-5'>
            <div className='text-[#FFFFFF] md:text-base text-xs font-semibold'>
                <p>{title1}</p>
            </div>
            <div className='text-[#21FAB4] md:text-base text-xs font-semibold'>
          <Link to={link}>  <p>{title2}</p> </Link>
            </div>
            </div> 
    </div>
  )
}

export default TextonTop