import React from 'react'

const Header = ({ description, image }) => {
  return (
    <div>
          <div className="flex bg-[#023059] py-10">
      <div className="flex-col lg:mx-20 md:mx-10 mx-5 space-y-10">
        <div className="text-white xl:text-5xl lg:text-4xl md:text-3xl sm:text-2xl text-xl xl:w-[750px] lg:w-[550px] md:w-[470px] sm:w-[375px] w-[290px] font-semibold ">
          <p> We have the pleasure of introducing ourselves as</p>
          
       
        </div>
        <div className="text-[#DEDEDE] lg:text-xl md:text-lg sm:text-base text-xs lg:w-[500px] md:w-[450px] sm:w-[400px] w-[290px] font-normal"> <p>{description}</p></div>
      </div>

      <div className="hidden md:block">
        <img src={image} alt="image" />
      </div>
    </div>
    </div>
  )
}

export default Header