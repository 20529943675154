import React from "react";
import E from "../../assests/e.png";
import F from "../../assests/f.png";
import Arrow1 from "../../assests/Arrow1.png";
import Rectangle from "../../assests/Rectangle 4.png";

const Services = () => {
  return (
    <div className="lg:flex block lg:mx-14 md:mx-10 mx-5 relative lg:space-x-20 space-x-0 lg:space-y-0 space-y-6 pb-20">
        
       
      <div className="flex-col space-y-2 ">
      <img src={Arrow1} alt="e" className="absolute xl:w-[500px] lg:w-[360px] lg:h-28 hidden lg:block  -top-28 left-72 xl:h-44" />
        <img src={E} alt="e" className="md:w-64 md:h-44 w-32 h-24" />
        <img src={Rectangle} alt="f" className="absolute md:-left-16  md:w-16 w-8 h-8 md:h-16 md:mx-52 mx-16 " />
        <img src={Rectangle} alt="f" className="absolute top-14 -left-48 md:top-24 md:-left-14 md:w-16 w-8 h-8 md:h-16 mx-80 rotate-180" />
        <img src={F} alt="f" className="md:w-52 md:h-44 w-28 h-24 mx-24 md:mx-52" />
      </div>
      <div className="flex-col  space-y-6">
        <p className="xl:text-xl lg:text-xl md:text-2xl text-sm xl:w-[390px] lg:w-[390px] md:w-[450px] w-[250px] text-[#023059] font-semibold">
          Services we provideLorem ipsum - Dolor sit amein elit. Phasellus , sit
          amet rutrum eratlibero vitae felis
        </p>
        <p className="xl:text-base lg:text-xs md:text-sm text-xs md:w-[580px]  xl:w-[450px] lg:w-[350px] w-[280px] text-[#7E7E7E] font-medium">
          Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet rutrum
          eratlibero vitae olor sit amet, aiscint amet, aiscin elit. ellus , sit
          amet rutrum eratlibero vitae felis - Dolor sit amet, aiscin elit.
          Phasellus , sit amet rutrum eratlibero vitae felis
        </p>
        <p className="xl:text-base lg:text-xs md:text-sm text-xs md:w-[580px]  xl:w-[450px] lg:w-[350px] w-[280px] text-[#7E7E7E] font-medium">
          Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet rutrum
          eratlibero vitae felis - Dolorit amet rutrum eratlibero vitae felis -
          Dolor sit amet, aiscint amet, aiscin elit. ellus , sit amet rutrum
        </p>
      </div>
    </div>
  );
};

export default Services;
