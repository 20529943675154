import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Content from '../components/Contact_us/Content'
import Header from '../components/Contact_us/Header'
import Footer from '../components/Footer'
import Nav from '../components/Nav'
import Sidebar from '../components/Sidebar'

const Contact_us = () => {
  const routePath = useLocation();
  useEffect(()=>{
  window.scrollTo(0,0);
},[routePath.pathname])
  return (
    <div>
        <Nav/>
        <Sidebar/>
        <Header/>
        <Content/>
        <Footer/>
    </div>
  )
}

export default Contact_us