import React from 'react'

const Cards4 = ({ id, img, title, description }) => {
  return (
    <div>
 <div class="box-content relative h-64 w-64  p-4 drop-shadow-xl rounded-2xl bg-white  hover:scale-110 duration-300 cursor-pointer">
     <span className='absolute top-0 text-8xl font-bold -mt-10 -ml-8 text-[#D8ECFF]'>{id}</span>
      <div className="flex-col text-center mx-9">
        {" "}
        <div className=" space-y-4  py-10  ">
          <div className="flex justify-center">
            <img src={img} alt="img" className="w-16 h-16 " />
          </div>
          <div className="text-[#023059] justify-center w-[190px] text-xl space-y-5 font-semibold ">
            <p>{title}</p>
            <p className="text-xs ">{description}</p>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Cards4