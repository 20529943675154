import React from "react";
import Line from "../../assests/Line2.png";
import Fam from "../../assests/fam.png";
import Self from "../../assests/self.png";
import Benifitss from "../../assests/benifits1.png";
import Speed from "../../assests/speed.png";
import Cards from "../Cards";

const Benifits = () => {
  const cardlist = [
    {
      sno: 1,
      image: Benifitss,
      title: "Benefit1",
      description:
        "Lorem ipsum dolor sit nisl, sit amet r erat libero vitae felis",
    },
    {
        sno: 2,
        image: Speed,
        title: "Speed",
        description:
          "Lorem ipsum dolor sit nisl, sit amet r erat libero vitae felis",
      },
      {
        sno: 3,
        image: Self,
        title: "Benifit2",
        description:
          "Lorem ipsum dolor sit nisl, sit amet r erat libero vitae felis",
      },
      {
        sno: 4,
        image: Fam,
        title: "Speed",
        description:
          "Lorem ipsum dolor sit nisl, sit amet r erat libero vitae felis",
      }
  ];
  return (
    <div className="xl:mx-20 lg:mx-20 md:mx-10 mx-5 py-10 flex-col items-center my-10">
      <div className="flex-col">
        <div className="flex py-5 text-xs text-[#023059] font-semibold">
          <img
            src={Line}
            alt="line"
            className="w-18 h-1 mt-2 flex items-center"
          />
          <span className="text-[#C8C8C8]">02.</span> <span> Benifits</span>
        </div>
        <div className="text-[#023059] md:text-3xl text-2xl  lg:text-4xl font-medium">
          <p>Why Choose Us?</p>
        </div>
        

        <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-col-1 xl:gap-10 lg:gap-20 md:gap-20 gap-10 my-8 justify-center">
            {
                cardlist.map((elem,index)=>(
                    <Cards
                    key={index}
                    sno={elem.sno}
                    img={elem.image}
                    title={elem.title}
                    description={elem.description}/>
                    ))
                }
        </div>
                </div>
    </div>
  );
};

export default Benifits;
