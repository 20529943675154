import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Discover from "../components/Discover";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import Avani2 from "../components/Products/Avani2";
import OurProduct from "../components/Products/OurProduct";
import Services from "../components/Products/Services";

const Product2 = () => {
  const routePath = useLocation();
  useEffect(()=>{
  window.scrollTo(0,0);
},[routePath.pathname])
  return (
    <div>
      <Nav />
      <Avani2 />
      <Services />
      <OurProduct />
      <Discover />
      <Footer />
    </div>
  );
};

export default Product2;
