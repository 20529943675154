import React from 'react'

const Text = ({title,description}) => {
  return (
    <div>
        <div className='flex-col justify-center items-center py-5'>
            <p className='text-center text-[#ffffffca] md:text-3xl text-lg font-semibold'>{title}</p>   
            <p className='text-center text-[#ffffff94] md:text-base text-xs font-semibold py-5'>{description}</p> 
            </div>  
    </div>
  )
}

export default Text