import React from 'react'
import Rectangle from "../assests/Rectangle 4.png";

const HeaderLanguage = ({image1,image2,video}) => {
  return (
    <div className="md:mx-10 mx-3 lg:mx-10 xl:mx-20 relative">
      <div className="text-[#023059] font-medium py-10 md:text-3xl lg:text-3xl xl:text-3xl text-base md:w-[700px] w-[300px]">
        <p>
          Upload Image and test{" "}
          <span className="font-bold">Local language extraction</span> application
          service with <span className="text-[#21FAB4] font-bold">AVANI</span>{" "}
        </p>
      </div>
      <div className="lg:flex block xl:space-x-72 lg:space-x-48 md:space-x-0 justify-start items-center">
        <div className="flex-col space-y-1 mb-10">
          <img src={image1} alt="smoke" className="w-[170px] h-[130px]" />
          <img
            src={Rectangle}
            alt="rect"
            className="w-10 h-10 absolute lg:bottom-[340px] bottom-0 md:top-[280px] top-[280px] left-[100px] md:left-[100px] lg:left-[105px]"
          />
          <img
            src={Rectangle}
            alt="rect"
            className="rotate-180 w-10 h-10 absolute lg:bottom-[383px] bottom-0 md:top-[240px] top-[240px] left-[260px] md:left-[260px] lg:left-[260px]"
          />
          <img
            src={image2}
            alt="smoke"
            className="w-[150px] mx-36 h-[130px] "
          />
        </div>
        <div className="py-5 xl:text-xl lg:text-xl md:text-xl text-sm xl:w-[520px] lg:w-[500px] md:w-[450px] w-[250px] text-[#023059] font-semibold">
          <p>
            Services we provideLorem ipsum - Dolor sit amein elit. Phasellus ,
            sit amet rutrum eratlibero vitae felis{" "}
          </p>
          <p className="text-xs font-medium text-[#7E7E7E] lg:w-[470px] md:w-[550px]  w-[290px] py-6">
            Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet
            rutrum eratlibero vitae olor sit amet, aiscint amet, aiscin elit.
            ellus , sit amet rutrum eratlibero vitae felis - Dolor sit amet,
            aiscin elit. Phasellus , sit amet rutrum eratlibero vitae felis
          </p>
          <p className="text-xs font-medium text-[#7E7E7E] lg:w-[470px] md:w-[550px] w-[290px]">
            Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet
            rutrum eratlibero vitae felis - Dolorit amet rutrum eratlibero vitae
            felis - Dolor sit amet, aiscint amet, aiscin elit. ellus , sit amet
            rutrum
          </p>
        </div>
      </div>
<div className="lg:flex block xl:space-x-60 lg:space-x-12 md:space-x-0 space-y-10 lg:space-y-0">
      <div className="flex-col  items-center ">
            <div className="flex py-5 xl:text-xl lg:text-xl md:text-xl text-sm xl:w-[390px] lg:w-[390px] md:w-[450px] w-[250px] text-[#023059] font-semibold">
              <p>
                Services we provide Lorem ipsum - Dolor sit amet, aiscin elit.
                Phasellus , sit amet rutrum eratlibero vitae felis
              </p>
            </div>

            <div className=" text-[#4F4F4F] xl:text-xs lg:text-xs md:text-xs text-xs md:w-[580px]  xl:w-[450px] lg:w-[350px] w-[280px] font-medium">
              <p>
                Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet
                rutrum eratlibero vitae felis - Dolorit amet rutrum eratlibero
                vitae felis - Dolor sit amet, aiscint amet, aiscin elit. ellus ,
                sit amet rutrum eratlibero vitae felis - Dolor sit amet, aiscin
                elit. Phasellus , sit amet rutrum eratlibero vitae felis
              </p>
            </div>
          </div>
          <div>
            <iframe
              src={video}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              className="flex lg:mx-10 md:mx-1 mx-0 rounded-2xl sm:w-[430px] sm:h-[210px] w-[280px] h-[150px]"
            ></iframe>
          </div>
          </div>
    </div>
  )
}

export default HeaderLanguage
