import React from "react";
import Twitter from "../assests/twitter.png";
import Facebook from "../assests/Facebook1.png";
import Instagram from "../assests/Insta1.png";
import Linkedin from "../assests/Linkedin1.png";
import Phone from "../assests/phn.png";
import Message from "../assests/msg.png";
import Location from "../assests/location.png";
import { Button, TextField } from "@mui/material";
import { createTheme,ThemeProvider } from '@mui/material/styles';
import blue from '@mui/material/colors/blue';
import { Link } from "react-router-dom";

const Cards8 = () => {

const theme = createTheme({
  palette: {
    secondary: {
        main:"#ffffff"
    },
  },
});
  return (
    <ThemeProvider
    theme={theme}>
    <div className="md:flex block relative">
      <div className="box-content border-2 md:z-10 lg:w-[450px] md:w-[400px] h-[450px] bg-slate-100 rounded-2xl">
        <div className="text-[#023059] lg:text-3xl md:text-2xl px-5 py-5 font-semibold">
          <p>Contact Information</p>
        </div>
        <div className="text-[#023059B8] text-base px-5 lg:w-[350px] md:w-[320px] font-normal">
          <p>Fill up the form and our Team will get back to you soon.</p>
        </div>
        <div className="flex-col md:text-base lg:text-base xl:text-base text-xs px-5 md:w-[350px] w-[200px] py-5 space-y-7 font-medium text-[#466784]">
          <div className="flex md:space-x-10 space-x-3 justify-start items-center">
            <img src={Phone} alt="phn" className="w-4 h-4" />
            <p>+91 78345267189</p>
          </div>
          <div className="flex md:space-x-10 space-x-3 justify-start items-center">
            <img src={Message} alt="msg" className="w-4 h-4" />
            <p>heyouremail@gmail.com</p>
          </div>
          <div className="flex md:w-[300px] w-[200px] md:space-x-10 space-x-3 justify-start items-center">
            <img src={Location} alt="location" className="w-4 h-4" />
            <p>Lorem ipsum dolor sit amet, consectet , Mumbai 400010</p>
          </div>
        </div>
        <div className="flex md:py-20 py-32 px-7">
          <div className="flex lg:space-x-10 md:space-x-5 space-x-12">
           <Link> <img src={Linkedin} alt="linkedin" className="w-5 h-5" /></Link>
           <Link><img src={Twitter} alt="twitter" className="w-5 h-5" /></Link>
           <Link><img src={Instagram} alt="insta" className="w-5 h-5" /></Link>
           <Link><img src={Facebook} alt="facebook" className="w-5 h-5" /></Link>
          </div>
          <div className="hidden md:block box-content bg-gradient-to-tl from-[#023059] to-[#21FAB4] w-24 h-28 absolute lg:left-[345px] md:left-[259px] xl:left-[358px] left-0  bottom-[450px]  rounded-tl-full md:bottom-0"></div>
        </div>
      </div>

      <div className="box-content md:-ml-3 md:mt-0 -mt-3  ml-0 lg:w-[450px] md:w-[400px] h-[450px] bg-[#144469] ">
        <div className="grid grid-rows-4 gap-7 md:w-80 w-72 py-10 px-10 ">
        <TextField id="standard-basic" label="Full Name" variant="standard"  sx={{ label: { color: 'white' },input:{color: 'white'} }} color="secondary"/>
        <TextField id="standard-basic" label="E-Mail" variant="standard" sx={{  label: { color: 'white' } ,input:{color: 'white'}}} color="secondary"/>
        <TextField id="standard-basic" label="Phone Number" variant="standard" sx={{  label: { color: 'white' },input:{color: 'white'} }} color="secondary"/>
        <TextField id="standard-basic" label="Message" variant="standard" sx={{  label: { color: 'white' },input: { color: 'white' } }} color="secondary" />
        </div>
        <div className="flex justify-end px-10">
        <button className="flex justify-center py-1 rounded-md px-3 bg-[#21FAB4] text-base font-medium text-[#012443]"><span>Send Message</span></button>
      </div>
      </div>
    </div>
    </ThemeProvider>
  );
};

export default Cards8;
