import FormGroup from "@mui/material/FormGroup";
import React, { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Button from "../components/Button";
import Button2 from "../components/Button2";
import Nav from "../components/Nav";
import Sidebar from "../components/Sidebar";
import Text from "../components/Text";
import TextField from "../components/TextField";
import TextonTop from "../components/TextonTop";
import { Link, useNavigate } from "react-router-dom";
import { base_url } from "../utils/base_URL";
import axios from "axios";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSignIn = () => {
    setError("");
    if (email.length > 0 && password.length > 0) {
      if(email.toString().includes("@gmail.com")){
      if (checked) {
      const requestBody = {
        email: email,
        password: password,
      };
      axios
        .post(
          `${process.env.SERVER_URL || base_url}auth/user/login`,
          requestBody,
          { header: {} }
        )
        .then(function (response) {
          console.log("successful RESPONSE ", response);
          if (response?.request?.status === 400) {
            
            navigate("/signin")
          } else if (response?.request?.status === 200) {
            
            navigate("/")
            //cookies.set("Token",response?.data?.user?.token)
  
          }
        })
        .catch(function (error) {
          console.log("error RESPONSE ", error);
          if (error?.response?.data?.errors?.error) 
          setError("A user with this email and password was not found.");
        });}
        else {
          setError(`Please click on "Remember me" and then Continue`)
        }}
        else{
          setError("Please enter a valid email")
        }
    } else {
      setError("This field may not be blank.");
    }
  };

  return (
    <>
      {" "}
      <Nav />
      <Sidebar />
      <div className="bg-[#023059] pb-40 pt-14 ">
        <div className="box-content bg-[#e6e8ef16] xl:w-[1100px] lg:w-[800px] md:w-[600px] w-[300px] mx-auto  h-[700px] md:h-[800px] rounded-xl">
          <TextonTop
            title1="Don’t have an account?"
            title2="Sign up!"
            link="/signup"
          />
          <Text
            title="Welcome Back to Karmaa Lab"
            description="Login into your account"
          />

          <div className=" space-y-5 mt-8">
            <TextField
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <TextField
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />

            <div className="md:flex items-center xl:space-x-52 lg:space-x-28 md:space-x-20 md:mx-[100px]  space-x-0 mx-[50px] lg:mx-[200px] xl:mx-[280px] ">
              <div className="flex items-center">
                <Switch
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <span className="text-[#21FAB4] flex-1">Remember me</span>
              </div>
              <Link to="/forgetpass">
                <span className="text-red-600 flex-1 md:mx-0 mx-7 ">
                  Recover Password
                </span>
              </Link>
            </div>

            <div className="xl:w-[500px] lg:w-[700px] w-200px ">
              <Button buttontext="Log In" onClick={handleSignIn} />
              {error.length > 0 && (
                <div className="flex justify-center items-center font-semibold my-5 text-white text-base xl:mx-[290px] lg:mx-[60px] md:mx-[5px] mx-[5px] text-center w-full">
                  <span>{error}</span>
                </div>
              )}
              {/* <div className="my-2 xl:mx-[540px] lg:mx-[410px] md:mx-[290px] mx-10 text-center text-[#21FAB4] text-xl font-semibold">
                <p>or</p>
              </div>
              <Button2 /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
