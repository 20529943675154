import React from 'react'
import { Link } from "react-router-dom";

const  Cards6 = ({title,img,description,button,link}) => {
  return (
    <div>
        <div class="box-content relative h-64 w-64 p-4 drop-shadow-xl rounded-2xl bg-white  hover:scale-110 duration-300 cursor-pointer">
<span className='box-content absolute top-[80px] left-[118px] border-2 bg-[#023059] w-12 rounded-full h-12'></span>
      <div className="flex-col text-center mx-2">
        {" "}
        <div className=" space-y-7 py-5">
            <p className='text-[#023059] justify-center text-xl  font-semibold'>{title}</p>
          <div className="flex justify-center">
           
            <img src={img} alt="img" className="w-8 h- z-10 " />
          </div>
          <div className="text-[#7E7E7E] justify-center w-[230px] text-xl font-semibold ">
            <p className="text-xs ">{description}</p>
          </div>
        </div>
        <Link to={link}>
        <div className='box-content border-2 text-[#023059] bg-[#E3F1FE] font-semibold px-3 py-1 rounded-md'>
      
            <button>{button}</button>
        </div></Link>
      </div>
    </div>
    </div>
  )
}

export default Cards6