import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Discover from "../components/Discover";
import Footer from "../components/Footer";
import Header from "../components/HeaderLicense";
import Nav from "../components/Nav";
import Licensee from "../assests/license1.png";
import Licensee2 from "../assests/license2.png";
import Sidebar from "../components/Sidebar";
import CardsComponent from "../components/CardsComponent";
import UploadImage from "../components/UploadImage";

const License1 = () => {
    const routePath = useLocation();
    useEffect(()=>{
    window.scrollTo(0,0);
  },[routePath.pathname])
  return (
    <div>
      <div>
        <Nav />
        <Sidebar />
        <Header
          image1={Licensee}
          image2={Licensee2}
          video="https://www.youtube.com/embed/jTX8MSw0Ufw"
        />
        <UploadImage/>
        <CardsComponent />
        <Discover />
        <Footer />
      </div>
    </div>
  );
};

export default License1;
