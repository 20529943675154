import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import About_us from "./pages/About_us";
import LandingPage from "./pages/LandingPage";
import Product1 from "./pages/Product1";
import Product2 from "./pages/Product2";
import Blogs2 from "./pages/Blogs2";
import { Provider } from "react-redux";
import { store } from "../src/store/store";
import Contact_us from "./pages/Contact_us";
import Smoking1 from "./pages/Smoking1";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Language1 from "./pages/Language1";

import License1 from "./pages/License1";

import Yoga1 from "./pages/Yoga1";
import Signup from "./pages/Signup";
import SignIn from "./pages/SignIn";
import ForgetPass from "./pages/ForgetPass";
import CheckMail from "./pages/CheckMail";
import NewPass from "./pages/NewPass";
import SetNewPass from "./pages/SetNewPass";

function App() {
  return (
    <Provider store={store}>
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/aboutus" element={<About_us />} />
            <Route path="/product1" element={<Product1 />} />
            <Route path="/product2" element={<Product2 />} />
            <Route path="/blogs2" element={<Blogs2 />} />
            <Route path="/contact" element={<Contact_us />} />
            <Route path="/smoking" element={<Smoking1 />} />
            <Route path="/yoga" element={<Yoga1 />} />
            <Route path="/license" element={<License1 />} />
            <Route path="/language" element={<Language1 />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgetpass" element={<ForgetPass />} />
          <Route path="/newpass" element={<NewPass />} />
          <Route path="/setnewpass" element={<SetNewPass />} />
          <Route path="/checkmail" element={<CheckMail />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer limit={1} />
      </div>
    </Provider>
  );
}

export default App;
