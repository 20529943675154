import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
// import { useSelector } from "react-redux";
// import { selectHeader } from "../slices/HeaderSlice";
import { Link } from "react-router-dom";

export default function Sidebar() {
  const [state, setState] = React.useState(false);
const listElement=[{title:"Home",link:"/"}, {title:"About",link:"/aboutus"},{title:"Product",link:"/product1"}, {title:"Blogs",link:"/blogs2"}, {title:"Contact us",link:"/contact"}, {title:"Profile",link:"/signin"}]
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "right" ? 150 : 250, backgroundColor:"#023059" ,height:"100vh", color:"#ffffff", display:"flex",alignItems:"start",justifyContent:"center"  }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {listElement.map(
          (elem, index) => (
            <ListItem key={index} disablePadding>
              <Link to={elem.link}>
              <ListItemButton>
                <ListItemText primary={elem.title} />
              </ListItemButton></Link>
            </ListItem>
          )
        )}
      </List>
    </Box>
  );

  return (
    <div className={`w-10 fixed top-6 right-6`}>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
          <div className="lg:hidden block" onClick={toggleDrawer(anchor, true)}>
            <MenuIcon className="text-white  text-2xl" />
          </div>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
