import React from "react";
import { Link } from "react-router-dom";
import Button from "../components/Button";
import Tick from "../assests/tick.png"
import Nav from "../components/Nav";
import Sidebar from "../components/Sidebar";
import Text from "../components/Text";
import TextField from "../components/TextField";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const SetNewPass = () => {
  return (
    <>
    {" "}
    <Nav />
    <Sidebar />
    <div className="bg-[#023059] pb-40 pt-14  ">
      <div className="box-content bg-[#e6e8ef16] xl:w-[1100px] lg:w-[800px] md:w-[600px] w-[310px] mx-auto py-20 h-[300px] rounded-xl">
      <div className="box-content mx-auto relative p-5 bg-[#21fab536] rounded-full w-6 h-6 ">
        <img src={Tick} alt="T" className="w-14 h-14 absolute left-2 -bottom-3" />
      </div>
        <Text
          title="Password reset"
          description="Your password has been successfully reset.
          Click below to sign In"
        />

        <div className="md:w-[500px] w-200px space-y-4 mt-8">
          
<div className="-mt-10">
          <Button buttontext="Continue" link="/signin"/>
          </div>    

          <div className="text-[#21FAB4AD]  text-sm xl:mx-[280px] lg:mx-[160px] md:mx-[40px] mx-[20px] my-7 sm:w-[500px] w-[250px] text-center">
            <Link to="/signin">
            <div className="flex space-x-4 justify-center">

              <ArrowBackIcon />
              <p>Back to Sign In</p>
            </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default SetNewPass