import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Discover from "../components/Discover";
import Footer from "../components/Footer";
import Header from "../components/HeaderSmoke";
import Nav from "../components/Nav";
import Smoke from "../assests/smoke.png";
import Smoke2 from "../assests/smoke2.png";
import Sidebar from "../components/Sidebar";
import CardsComponent from "../components/CardsComponent";
import UploadImage from "../components/UploadImage";

const Smoking1 = () => {
    const routePath = useLocation();
    useEffect(()=>{
    window.scrollTo(0,0);
  },[routePath.pathname])
  return (
    <div>
      <Nav />
      <Sidebar />
      <Header
        image1={Smoke}
        image2={Smoke2}
        video="https://www.youtube.com/embed/jTX8MSw0Ufw"
      />
      <UploadImage/>
      <CardsComponent />
      <Discover />
      <Footer />
    </div>
  );
};

export default Smoking1;
