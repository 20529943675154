import React from "react";
import Line from "../../assests/Line2.png";
import Speed from "../../assests/speed.png";

const Highlights = () => {
  return (
    <div className=" bg-gradient-to-bl outline-none drop-shadow-xl from-[#ffffff] to-[#ACFFE4]">
      <div className="my-10 md:mx-10 mx-5 lg:mx-20 py-10 block lg:flex items-center xl:space-x-60 lg:space-x-32 md:space-x-16 space-x-0 ">
        <div className="flex-col">
          <div className="flex py-5 text-xs text-[#023059] font-semibold">
            <img
              src={Line}
              alt="line"
              className="w-18 h-1 mt-2 flex items-center"
            />
            <span className="text-[#C8C8C8]">03.</span> <span>SERVICES</span>
          </div>
          <div className="text-[#023059] lg:text-3xl xl:text-4xl md:text-4xl sm:text-2xl text-xl font-bold space-y-2 ">
            <p>
              Here are Some highlights <span className="font-semibold">of</span>{" "}
            </p>
            <p>
              <span className="font-semibold">solution we provide for our</span>
            </p>
            <p>
              <span className="font-semibold">client</span>
            </p>
          </div>
          <div className="xl:w-[430px] lg:w-[400px] md:w-[430px] w-[300px] xl:text-base md:text-base lg:text-sm text-xs text-[#7A7A7A] font-semibold mt-9">
            <span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              fringilla felis tortor, sit amet malesuada neque luctus sed.
              Pellentesque nisl, sit amet rutrum erat libero vitae felis
            </span>
          </div>
          <div className="flex justify-center items-center mt-11 w-32 h-10  py-1 rounded-md px-3 bg-[#012443] text-base font-semibold text-[#21FAB4]">
            <button>
              <span>See More</span>
            </button>
          </div>
        </div>
        <div className="flex justify-center items-center my-10 lg:my-0">
          <div className="grid sm:grid-cols-2 grid-cols-1 md:gap-10  gap-5">
          {Array(6)
            .fill()
            .map((_, index) => (
                <div className="flex items-center space-x-2 box-content rounded-lg bg-[#bef6e3] hover:bg-white md:px-10 px-5 md:py-4 py-4">
                <img src={Speed} alt="speed" className="md:w-10 md:h-10 w-5 h-5" />
                <p className="md:text-base text-sm">Lorem ipsum </p>
              </div>
              ))}
           </div>
        </div>
      </div>
    </div>
  );
};

export default Highlights;
