import { createSlice } from "@reduxjs/toolkit";

export const headerSlice = createSlice({
  name: "header",
  initialState: {
    header: null
  },
  reducers: {
    show: (state, action) => {
      state.header = action.payload;
    },
    hide:(state, action)=>{
      state.header = null;
    }
  },
});

export const { show, hide } = headerSlice.actions;

export const selectHeader = (state) => state.header.header;

export default headerSlice.reducer;