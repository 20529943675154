import React from "react";
import Line2 from "../../assests/Line2.png";
import Line6 from "../../assests/Line 6.png";
import C from "../../assests/c.png";
import D from "../../assests/d.png";
import Wellness1 from "../../assests/Wellness1.png";
import Wellness2 from "../../assests/Wellness2.png";

const HRI = () => {
  return (
    <div className="relative">
      <div className="absolute top-0 w-52 h-44 right-0 opacity-50 md:flex hidden  rounded-full bg-cyan-50">
        <img src={D} alt="d" />
      </div>
      <div className="md:mx-10 xl:mx-10 mx-5 lg:mx-20 flex items-center space-x-60 my-10 ">
        <div className="flex-col">
          <div className="flex py-5 text-xs text-[#023059] font-semibold">
            <img
              src={Line2}
              alt="line"
              className="w-18 h-1 mt-2 flex items-center"
            />
            <span className="text-[#C8C8C8]">04.</span>{" "}
            <span>Wellness@Mybizmo</span>
          </div>
          <div className="text-[#023059] md:text-4xl text:3xl  font-medium space-y-2 mb-10">
                  <p>Wellness@Mybizmo</p>
          </div>
          <div className="lg:flex block xl:space-x-[500px] lg:space-x-[150px] space-x-0 lg:space-y-0 space-y-10  ">
            <div className="flex-col justify-start items-center">
              <div className="text-[#4F4F4F] md:text-sm text-xs  md:w-[450px] w-[290px] font-medium">
                {" "}
                <p>
                  rem ipsum - Dolor sit amet, aiscin elit. ellus , sit amet
                  rutrum eratlibero vitae felis - Dolor sit amet, aiscint amet,
                  aiscin elit. ellus , sit amet rutrum eratlibero vitae felis -
                  Dolor sit amet, aiscin elit. Phasellus , sit amet rutrum
                  eratlibero vitae felis
                </p>
              </div>
            </div>
            <div className=" md:w-72 md:h-44 w-44 h-28 flex items-center">
              {" "}
              <img src={Wellness2} alt="logo" className="md:block hidden w-[250px] h-[200px] mx-0 md:-mx-10 lg:mx-0" />
           <img src={Wellness1} alt="logo" className="md:hidden block  w-[150px] h-[150px] md:mx-0 mx-14" />
            </div>
          </div>
          <div className="flex drop-shadow-2xl md:mx-0  mx-16 justify-center  lg:mx-0 items-center w-32 h-10 mt-0 lg:-mt-20 py-1 rounded-md px-3 bg-[#012443] text-base font-semibold text-[#21FAB4]">
            <button>
              <span>Know More</span>
            </button>
          </div>
          <div className="absolute xl:bottom-44 xl:right-[680px] lg:bottom-44 lg:right-[580px] md:bottom-64 md:flex hidden  md:right-[300px] w-32 h-32 opacity-50 rounded-full  drop-shadow-xl  bg-cyan-50">

        <img src={C} alt="c" />
      </div>
        </div>
      </div>
      <div className=" mt-36 md:mx-10 mx-5 my-10 xl:mx-10 lg:mx-10 grid lg:grid-cols-4 md:grid-cols-2 xl:gap-20 lg:gap-80 md:gap-14 gap-16">
                <div className="lg:flex md:hidden block absolute xl:bottom-4 lg:bottom-3 bottom-24  text-5xl font-extrabold w-[290px] xl:space-x-[280px] space-x-0 text-[#CFE5F2] text lg:space-x-[240px] md:space-y-[110px]  space-y-[120px]">
                <p className="lg:my-24 my-0">01.</p>
                <p>02.</p>
                <p>03.</p>
              </div>
      {Array(3)
            .fill()
            .map((_, index) => (
                <>
              <div className="flex items-center text-sm text-black md:w-[290px] w-[250px] xl:space-x-10 lg:space-x-5 md:space-x-10 font-medium ">
                <p>
                  {" "}
                  Lorem ipsum -
                  <span className="text-[#454545]">
                    Dolor sit amet, aiscin elit. Phasellus , sit amet rutrum
                    eratlibero vitae felis - Dolor sit amet, aiscin elit. PhasLorem
                    ipsum - Dolor sit amet,
                  </span>
                </p>
                <img src={Line6} alt="line"  className={index===2 &&`hidden` || index===1 &&`md:block hidden` || index===0 &&`md:block hidden` } />
              </div>
              </> 
              ))}
      
      </div>
     
    </div>
  );
};

export default HRI;
