import React from "react";
import { Link } from "react-router-dom";
import AboutImage from "../../assests/aboutimage.png";
import Line from "../../assests/Line2.png";

const About = () => {
  return (
    <div className="md:mx-10 mx-5 lg:mx-20 py-10 block md:flex items-center xl:space-x-60 lg:space-x-32 md:space-x-16 space-x-0">
      <div>
        <img src={AboutImage} alt="about" />
      </div>
      <div className="flex-col" >
        <div className="flex py-5 text-xs text-[#023059] font-semibold">
          <img src={Line} alt="line" className="w-18 h-1 mt-2 flex items-center" />
          <span className="text-[#C8C8C8]">01.</span> <span> About Us</span>
        </div>
        <div className="text-[#023059] md:text-2xl text-xl lg:text-4xl font-medium">
          <p>Lorem ipsum dolor sit</p>
          <p>
            amet,<span className="text-[#21FAB4]"> Consectetur</span>
          </p>
        </div>
        <div className="lg:w-[400px] md:w-[350px] sm:w-[290px] lg:text-base md:text-sm text-xs font-medium text-[#7E7E7E] py-8">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
            fringilla felis tortor, sit amet malesuada neque luctus sed.
            Pellentesque nisl, sit amet rutrum erat libero vitae felis
          </p>
        </div>
        <div>
          <p>
            <Link className="hover:text-blue-800 text-[#023059] font-bold text-xs hover:underline underline-offset-8 decoration-1 decoration-[#21FAB4]">
              Read More
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
