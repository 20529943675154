import React from "react";
//import Profile from "../../assests/ProfileCircle.png";

const Cards = ({ sno, img, title, description }) => {
  return (
    <div class="box-content h-60 w-60 md:h-64 md:w-64 p-4 drop-shadow-xl rounded-2xl bg-white  hover:scale-110 duration-300 cursor-pointer">
      <div className={`box-content border-2 font-semibold -ml-4 rounded-br-3xl bg-[#D8ECFF] w-12 h-10 `}> <span className="text-[#023059] flex items-center justify-center mt-2">0{sno}.</span> </div>
    
      <div className="flex-col justify-center text-center">
        {" "}
        <div className=" space-y-4 py-5  ">
          <div className="flex justify-center">
            <img src={img} alt="img" className="w-16 h-16 " />
          </div>
          <div className="text-[#023059]  text-xl space-y-5 font-semibold ">
            <p>{title}</p>
            <p className="text-xs mx-auto w-[150px] ">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
