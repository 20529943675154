import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Accordian = ({title,description}) => {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <p className='text-[#023059] font-semibold '>{title}</p>
        </AccordionSummary>
        <AccordionDetails>
          <p className='text-[#1d191994] font-semibold text-sm'>
            {description}
          </p>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default Accordian
