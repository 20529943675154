import React from "react";
import Line2 from "../../assests/Line2.png";
import Line4 from "../../assests/Line4.png";
import Ball from "../../assests/ball.png";
import Game from "../../assests/game.png";

const Vision = () => {
  return (
    <div className="flex  bg-gradient-to-bl outline-none  drop-shadow-xl from-[#ffffff] to-[#ACFFE4] ">
      <div className="lg:mx-20 md:mx-10 mx-5 py-5 flex items-center xl:space-x-80 lg:space-x-20 space-x-40 my-10 ">
        <div className="flex-col">
          <div className="flex py-5 text-xs text-[#023059] font-semibold">
            <img
              src={Line2}
              alt="line"
              className="w-18 h-1 mt-2 flex items-center"
            />
            <span className="text-[#C8C8C8]">02.</span>{" "}
            <span>VISION & MISSION</span>
          </div>

          <div className="lg:flex block text-4xl text-[#023059] justify-center items-start xl:space-x-32 lg:space-x-12  font-semibold">
            <div className="flex-col space-y-5 ">
              <span>Our Vision </span>
              <div className="md:w-[380px] w-[280px] text-sm">
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Phasellusllentesque nisl, sit amet rutrum erat libero vitae
                  felis
                </span>
              </div>
              <div className="text-base space-y-6 py-10 text-[#023059]">
                <div className="flex space-x-3">
                  <img src={Ball} alt="ball" className="w-5 h-5" />
                  <p>Lorem ipsum dolor sit amet</p>
                </div>
                <div className="flex space-x-3">
                  <img src={Ball} alt="ball" className="w-5 h-5" />
                  <p>Lorem ipsum dolor sit amet</p>
                </div>
                <div className="flex space-x-3">
                  <img src={Ball} alt="ball" className="w-5 h-5" />
                  <p>Lorem ipsum dolor sit amet</p>
                </div>
              </div>
            </div>
            <div>
              <img src={Line4} alt="line" className="lg:h-[350px] lg:w-1 md:h-1 md:w-[250px] h-1 w-[450px]  rotate-180 my-10 lg:rotate-0" />
            </div>
            <div className="flex-col justify-center space-y-5 ">
              <span>Our Mission</span>
              <div className="md:w-[380px] w-[280px] text-sm">
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Phasellusllentesque nisl, sit amet rutrum erat libero vitae
                  felis
                </span>
              </div>
              <div className="text-base space-y-6 py-10  text-[#023059]">
                <div className="flex space-x-3">
                  <img src={Ball} alt="ball" className="w-5 h-5" />
                  <p>Lorem ipsum dolor sit amet</p>
                </div>
                <div className="flex space-x-3">
                  <img src={Ball} alt="ball" className="w-5 h-5" />
                  <p>Lorem ipsum dolor sit amet</p>
                </div>
                <div className="flex space-x-3">
                  <img src={Ball} alt="ball" className="w-5 h-5" />
                  <p>Lorem ipsum dolor sit amet</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src={Game} alt="game" className="hidden xl:block mt-80 w-52 h-52" />
    </div>
  );
};

export default Vision;
