import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Article from '../components/Blogs/Article'
import Discover from '../components/Discover'
import Footer from '../components/Footer'
import Nav from '../components/Nav'
import Sidebar from '../components/Sidebar'

const Blogs = () => {
  const routePath = useLocation();
  useEffect(()=>{
  window.scrollTo(0,0);
},[routePath.pathname])
  return (
    <div>
        <Nav/>
        <Sidebar/>
        <Article/>
        <Discover/>
        <Footer/> 
    </div>
  )
}

export default Blogs