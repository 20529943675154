import React from "react";
import Line2 from "../../assests/Line2.png";
import Man from "../../assests/man.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import Cards1 from "../Cards1";

const Testimonials = () => {
  const cardlist1 = [
    {
      image: Man,
      title: "Mr. Rajesh Singh",
      about: "UI/UX Designer",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisesque nisl, sit amet rutrum erat libero vitae felis",
    },
    {
      image: Man,
      title: "Mr. Rajesh Singh",
      about: "UI/UX Designer",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisesque nisl, sit amet rutrum erat libero vitae felis",
    },
  ];
  return (
    <div>
      <div className="md:mx-10 mx-5 lg:mx-20 py-10 xl:flex block items-center xl:space-x-48 lg:space-x-20 my-10 ">
        <div className="flex-col">
          <div className="flex py-5 text-xs text-[#023059] font-semibold">
            <img
              src={Line2}
              alt="line"
              className="w-18 h-1 mt-2 flex items-center"
            />
            <span className="text-[#C8C8C8]">05.</span>{" "}
            <span>TESTIMONIALS</span>
          </div>
          <div className="text-[#023059] xl:text-4xl  lg:text-4xl md:text-4xl sm:text-3xl text-2xl  font-semibold space-y-3 ">
            <p> Don’t believe us</p>
            check what <span className="text-[#21FAB4]"> Client Say</span>
            <span className="font-semibold"> about us !</span>
          </div>

          <div className="flex justify-start items-center mt-10 space-x-10">
            <Link>
              <div className=" box-content hover:bg-[#023059] drop-shadow-2xl border-[#023059] border-2 rounded-full  px-4 py-4">
                <ArrowBackIcon className="text-[#21FAB4]" />
              </div>
            </Link>
            <Link>
              <div className=" box-content hover:bg-[#023059]  drop-shadow-2xl border-[#023059] border-2 rounded-full py-4  px-4">
                <ArrowBackIcon className="rotate-180 text-[#21FAB4]" />
              </div>
            </Link>
          </div>
        </div>
        <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-col-1 xl:gap-60 gap-10 lg:gap-80 md:gap-10 my-8 justify-center">
          {cardlist1.map((elem, index) => (
            <Cards1
              key={index}
              img={elem.image}
              title={elem.title}
              about={elem.about}
              description={elem.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
