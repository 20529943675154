import React from 'react'
import Cards9 from "../components/Cards9";
import AI from "../assests/ai.png";
import AI2 from "../assests/ai2.png";

const CardsComponent = () => {
    const cardlist = [
        {
          title: "Services we provide",
          image: AI,
          description:
            "Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet ruteratlibero vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum - Dolor sit",
          button: "Test Application",
        },
        {
          title: "Services we provide",
          image: AI2,
          description:
            "Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet ruteratlibero vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum - Dolor sit",
          button: "Test Application",
        },
        {
          title: "Services we provide",
          image: AI,
          description:
            "Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet ruteratlibero vitae felis - Dolor sit amet, aiscin elit. Pharem ipsum - Dolor sit",
          button: "Test Application",
        }
    ];
  return (
    <div className=' pt-5 pb-40 space-y-10'>
        <div className='md:mx-10 mx-3 lg:mx-10 xl:mx-20  text-[#023059] text-2xl font-semibold'><p>More applications</p></div>
       <div className="md:mx-10 mx-3 lg:mx-10 xl:mx-20 grid lg:grid-cols-3 xl:grid-cols-3 md:grid-cols-2 grid-cols-1 xl:gap-0 lg:gap-10 md:gap-10 gap-10">
          {cardlist.map((elem, index) => (
            <Cards9
              key={index}
              title={elem.title}
              img={elem.image}
              description={elem.description}
              button={elem.button}
            />
          ))}
        </div>
    </div>
  )
}

export default CardsComponent
