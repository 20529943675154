import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Nav from "../components/Nav";
import Sidebar from "../components/Sidebar";
import Text from "../components/Text";
import TextField from "../components/TextField";
import { base_url } from "../utils/base_URL";
import Cookie from 'cookie-universal'
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ForgetPass = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navigate=useNavigate()
  const cookies = Cookie()
  
  const handleForgotPassword = () => {
    // console.log(errors);
 
     setError("");
     if (email.length > 0) {
       const requestBody = {
         email: email
       };
       axios
         .post(`${process.env.SERVER_URL || base_url}auth/user/forgot/password`, requestBody, {
           headers: {
              // Authorization: "Token " + cookies.get("token"),
           },
         })
         .then(function (response) {
           console.log("successful Entered OTP ", response.data);
           if (response?.request?.status === 500) {
             navigate("/forgotpass")
             setError("You have not entered a valid Email")
           } else if (response?.request?.status === 200) {
             navigate("/checkmail")
             cookies.set('token',response?.data?.token)
             cookies.set('otp',response?.data?.otp)
             // cookies.set('email',email.toString())
             localStorage.setItem('email',email)
           }
         })
         .catch(function (error) {
           console.log("ERROR IN Entered OTP ", error);
           setError("This type of Email does not exist")
         });
     } else {
       setError("You have not entered your Email");
     }
   };
  // console.log(email)
  return (
    <>
      {" "}
      <Nav />
      <Sidebar />
      <div className="bg-[#023059] pb-40 pt-14 ">
        <div className="box-content bg-[#e6e8ef16] xl:w-[1100px] lg:w-[800px] md:w-[600px] w-[300px] mx-auto py-36 h-[500px] rounded-xl">
          <Text
            title="Forgot Password?"
            description="No worries, we’ll send you reset instructions"
          />

          <div className="md:w-[500px] w-200px space-y-8 mt-8">
            <TextField
              type="email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />

            <Button buttontext="Reset Password" onClick={handleForgotPassword}/>
            {error.length > 0 && (
                <div className="flex justify-center items-center font-semibold text-white text-base xl:mx-[290px] lg:mx-[170px] md:mx-[50px] mx-[5px] text-center w-full">
                  <span>{error}</span>
                </div>
              )}

            <div className="text-[#21FAB4AD]  text-sm xl:mx-[280px] lg:mx-[160px] md:mx-[40px] mx-[20px] my-7 sm:w-[500px] w-[250px] text-center">
              <Link to="/signin">
                <div className="flex space-x-4 justify-center">
                  <ArrowBackIcon />
                  <p>Back to Sign In</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPass;
