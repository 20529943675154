import React from "react";
import Cards8 from "../Cards8";

const Header = () => {
  return (
    <div className="bg-[#023059] py-5">
      <div className="text-[#ffffff] md:mx-10 mx-5 lg:mx-20 md:text-3xl mb-7 text-xl lg:text-4xl font-medium">
        <p>Get in touch with us</p>
      </div>
      <div className="md:mx-10 mx-5 lg:mx-20 text-xs md:text-sm lg:text-base xl:text-base mb-7 font-normal text-[#ffffff] md:w-[660px] lg:w-[660px] xl:w-[660px] w-[300px]">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
          frietur adipiscing elit. Phasellus fringilla felis tortor, sitet
          rutrum erat libero vitae fel
        </p>
      </div>
      <div className=" md:mx-10 mx-5 lg:mx-20">
               <Cards8/>
               </div>
 
    </div>
  );
};

export default Header;
