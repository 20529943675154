import React from 'react'

const Header = ({ description, image }) => {
  return (
    <div className="flex relative bg-[#023059] py-10">
    <div className="flex-col lg:mx-20 md:mx-10 mx-5 space-y-10">
      <div className="text-white  xl:text-5xl lg:text-4xl md:text-3xl sm:text-2xl text-2xl xl:w-[750px] lg:w-[550px] md:w-[470px] sm:w-[375px] w-[290px] font-semibold ">
        <p> We have the pleasure of introducing ourselves as</p>
      </div>
      <div className="text-[#DEDEDE] lg:text-xl md:text-base sm:text-base text-sm lg:w-[500px] md:w-[400px] sm:w-[400px] w-[280px] font-normal"> <p>{description}</p></div>
    </div>

    <div className="hidden md:block absolute bottom-0 right-5 lg:w-80  w-60 ">
      <img src={image} alt="image" />
    </div>
  </div>
  )
}

export default Header