import React from "react";

const TextField = ({ type, placeholder, value, onChange }) => {
  return (
    <div>
      <div className=" flex border-2 xl:mx-72 lg:mx-48 md:mx-24 mx-7 md:text-lg text-sm text-[white] border-slate-400  sm:space-x-3 space-x-0 rounded-md xl:px-10 lg:px-7 px-3 xl:w-[500px] lg:w-[450px] md:w-[400px] sm:w-[350px] w-[250px] py-4  bg-gradient-to-r from-[#8d9eac70] to-[#0230592a]">
        <input
          type={type}
          placeholder={placeholder}
          className="bg-transparent flex-1 font-medium outline-none"
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default TextField;
