import React from "react";
//import { Link } from "react-router-dom";
//import Line2 from "../../assests/Line2.png";
import Avani from "../../assests/AVANI.png";

const Avani1 = () => {
  return (
    <div className="lg:mx-14 md:mx-10 mx-5 py-2 flex items-center space-x-60 my-10">
      <div className="flex-col">
        <div className="text-[#023059] sm:text-4xl text-xl font-medium space-y-2 md:mb-20 mb-5">
          <p>Here are some great service</p>
          <p>
            {" "}
            with outstanding value through{" "}
            <span className="font-extrabold bg-gradient-to-b from-[#023059] to-[#21FAB4] inline-block text-transparent bg-clip-text">
              {" "}
              AVANI{" "}
            </span>
          </p>
        </div>
        <div className="lg:flex justify-center items-center block xl:space-x-[400px] lg:space-x-[170px] space-x-0 lg:space-y-0 space-y-10">
          <div className="box-content border-2 md:w-52 md:h-32 w-32 h-24 px-10 rounded-xl  drop-shadow-xl text-xl font-medium flex justify-center items-center">
            {" "}
           <img src={Avani} alt="avani" />
          </div>
          <div className="flex-col space-y-10">
            <div className="text-[#4F4F4F] xl:text-base lg:text-xs md:text-sm text-xs  space-y-6  xl:w-[500px] lg:w-[450px] md:w-[580px] w-[280px] font-medium">
              {" "}
              <p>
                Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet
                rutrumhbbbb eratlibero vitae felis - Dolor sit amet, aiscin
                elit. PhasLorem ipsum - Dhu sit amet, aiscin elit. ellus , sit
                amet rutrum eratlibero vitae felis - Dolor sit amet, aiscint
                amet, aiscin elit. ellus , sit amet rutrum eratlibero vitae
                felis - Dolorhh sit amet, aiscin elit. Phasellus , sit amet
                rutrum eratlibero vitae felis
              </p>
              <p> 
              Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet
              rutrumhbbbb eratlibero vitae felis - bero vitae felis - Dolorhh
              sit amet, aiscin elit. Phasellus , sit amet rutrum eratlibero
              vitae tlibero vitae felis - bero vitae felis - Dolorhh sit amet,
              aiscin elit. Phasellus , sit amet rutrum eratlibero vitae felis
          </p>
            </div>
          </div>
        </div>
        <div className="lg:flex block space-x-0 lg:space-y-0 space-y-10 justify-between  items-center my-10 lg:my-20">
          <div className="flex-col  items-center ">
            <div className="flex py-5 xl:text-xl lg:text-xl md:text-2xl text-sm xl:w-[390px] lg:w-[390px] md:w-[450px] w-[250px] text-[#023059] font-semibold">
              <p>
                Services we provideLorem ipsum - Dolor sit amet, aiscin elit.
                Phasellus , sit amet rutrum eratlibero vitae felis
              </p>
            </div>

            <div className=" text-[#4F4F4F] xl:text-base lg:text-xs md:text-sm text-xs md:w-[580px]  xl:w-[450px] lg:w-[350px] w-[280px] font-medium">
              <p>
                Lorem ipsum - Dolor sit amet, aiscin elit. Phasellus , sit amet
                rutrum eratlibero vitae felis - Dolorit amet rutrum eratlibero
                vitae felis - Dolor sit amet, aiscint amet, aiscin elit. ellus ,
                sit amet rutrum eratlibero vitae felis - Dolor sit amet, aiscin
                elit. Phasellus , sit amet rutrum eratlibero vitae felis
              </p>
            </div>
          </div>
          <div>
            <iframe
              src="https://www.youtube.com/embed/jTX8MSw0Ufw"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              className="flex lg:mx-10 md:mx-1 mx-0 rounded-2xl sm:w-[430px] sm:h-[210px] w-[280px] h-[150px]"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Avani1;
