import React from 'react'
import Cards2 from '../Cards2'
import Line2 from "../../assests/Line2.png";
import Blank from "../../assests/blankprofile.png";

const TeamMembers = () => {
    const cardlist2 = [
        {
          
          image: Blank,
          title: "Mr. Rajesh Deshmukh",
          about:"Profession",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisesque nisl, sit amet rutrum erat libero vitae felis",
        },
        {
            
            image: Blank,
            title: "Mr. Rajesh Deshmukh",
            about:"Profession",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipisesque nisl, sit amet rutrum erat libero vitae felis",
          },
          {
          
            image: Blank,
            title: "Mr. Rajesh Deshmukh",
            about:"Profession",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipisesque nisl, sit amet rutrum erat libero vitae felis",
          },
          {
              
              image: Blank,
              title: "Mr.Rajesh Deshmukh",
              about:"Profession",
              description:
                "Lorem ipsum dolor sit amet, consectetur adipisesque nisl, sit amet rutrum erat libero vitae felis",
            }
        ];
  return (
    <div>
         <div className=" xl:mx-20 lg:mx-20 md:mx-10 mx-3 py-10 flex-col items-center my-10 ">
        <div className="flex-col">
          <div className="flex py-5 text-xs lg:mx-0 md:mx-0 mx-5 text-[#023059] font-semibold">
            <img
              src={Line2}
              alt="line"
              className="w-18 h-1 mt-2 flex items-center"
            />
            <span className="text-[#C8C8C8]">03.</span>{" "}
            <span>Team members</span>
          </div>
         <div className='text-4xl text-[#023059] lg:mx-0 md:mx-0  mx-5 font-semibold'>
            <p>OUR TEAM</p>
         </div>
        </div>
        <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 grid-col-1 xl:gap-5 lg:gap-5 md:gap-10 gap-10 my-8 justify-center">
        {
                cardlist2.map((elem,index)=>(
                    <Cards2
                    key={index}
                    img={elem.image}
                    title={elem.title}
                    about={elem.about}
                    description={elem.description}/>
                ))
            }
        </div>
      </div>
    </div>
  )
}

export default TeamMembers