import React from "react";

const Discover = () => {
  return (
    <div className="md:flex block space-y-5 md:space-y-0 py-20 bg-[#023059]">
      <div className=" flex-1 font-semibold sm:text-3xl text-2xl md:mx-10 mx-5 lg:mx-20 text-[#ffffff]">
        <p>Develop with advanced </p>
        <p> technology now</p>
      </div>
      <div className="flex justify-center items-center w-32 h-10 xl:mx-96 lg:mx-52 md:mx-28 sm:mx-10 mx-5 py-1 rounded-md px-3 bg-[#21FAB4] text-base font-semibold text-[#012443]">
        <button><span>Get Started</span></button>
      </div>
    </div>
  );
};

export default Discover;
